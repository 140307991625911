.swiper_image {
    background: #000;
}
.swiper_img {
    opacity: 0.5;
    
}
.swiper_h3_1 {
    font-size: 25px;
}
@media screen and (max-width: 768px) {
    .swiper_slide {
        height:1000px;
    }
    .swiper_img {
        position: fixed;
        top: 0px;
        left: 0px;
        min-width: 100%;
        min-height: 100%;
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
        opacity:70%;
        float: none;
        display: flex;
    }
    .swiper_div {
        top:10px;
        width:100%;
        left:17px;     
    }
    .swiper_p {
        width: 370px;
        font-size: 15px;
    }
    .swiper_h3_1 {
        font-size: 25px;
    }
    /* .swiper_p1 {
        padding-top: 20px;
    } */
 }

 @media screen and (max-width: 360px) {
    .swiper_slide {
        height:1000px;
    }
    .swiper_img {
        position: fixed;
        top: 0px;
        left: 0px;
        min-width: 100%;
        min-height: 100%;
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
        opacity:70%;
    }
    .swiper_div {
        top:10px;
        width:100%;
        left:17px; 
            
    }
    .swiper_h3_2 {
        width:350px;
        font-size: 42px;
    }
    .swiper_p {
        width: 350px;
        font-size: 15px;
    }
    .swiper_h3_1 {
        font-size: 24px;
    }
 }