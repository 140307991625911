.price__container {
    margin:0;
    padding:0;
    box-sizing: border-box;
    font-family: sans-serif;
    
    position: relative;
    display: inline-block;
}

/* Настройки маркированного списка */
.hover-effect-scale {
    list-style:none;
    margin: 0 auto;
    width: 100%;
    text-align: center;
    padding: 0;
}
.hover-effect-scale li {
    position: relative;
    display: inline-block;
    background-color: #fff;
    margin: 0;
   
    box-shadow: 1px 3px 1px 0 rgba(0, 0, 0, 0.08);
    overflow: hidden;
}
/* Заголовок, текст и ссылка */
.hover-effect-scale li h3{
    color: #fff;
    margin: 5px;
}
.hover-effect-scale li p{
    color: #fff;
    margin: 5px;
}
.hover-effect-scale li a {
    display: block;
    text-decoration: none;
    padding: 15px;
    color: #fff;
    background: #000;
    border-radius: 5px;
    margin: 0 auto;
    transition: all 0.4s ease-in-out;
}
.hover-effect-scale li a:hover {
    background: #454545;
    color: #fff;
}
/* Выезжающий блок с текстом */ 
.hover-effect-scale li > div {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    flex-direction: column;
   
}
.link__pointer {
    cursor: pointer;
}
.hover-effect-scale li:hover > div {
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
}
.hover-effect-scale li div {
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.35); /* Фон блока при наведении */
    transition: all 0.4s ease-in-out;
    color: #fff;
}
.hover-effect-scale li img {
    transition: all 0.2s linear;
}
.hover-effect-scale li:hover img {
    transform: scale(1.3);
}
.hover-effect-scale li:hover div {
    opacity: 1;
}

.price__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    max-width: 1300px;
    width: 100%;
    margin:auto;
}

.price__section {
    min-height: 100vh;
    display: grid;
    flex-direction: column;
    align-items: center;
    background-image: url('../../images/bg11.jpeg');
    background-size: cover auto;
    background-attachment: fixed; 
    
    color:white;
}

.price__h1 {
    font-size: 50px;
    line-height: 60px;
    text-align: center;
    margin-bottom:40px;
    color:white;
    
}
.price__h3 {
    color:white;
    
}

.price__h2 {
    color: white;
    font-size: 24px;
    line-height: 60px;
    text-align: center;
    margin-bottom:40px;
    cursor: pointer;
}

.price__card_main{
    flex: 0 0 calc(33.33% - 20px);
    max-width: calc(33.33% );
    
}
.price__h2 {
    justify-content: center;
    text-align: center;
}
.price__cards_2 {
    display: flex;
    flex-wrap:wrap;
    justify-content: center;
    color:white;
    
}
.title__list {
    display: flex;
    flex-wrap:wrap;
    justify-content: space-between;
    margin-left: 20px;
}

.price__cards {
    display: flex;
    flex-wrap:wrap;
    justify-content: space-between;
    color:white;
    
}

.price__card {
    margin-top: 10px;
    margin-left:5px;
    margin-right:5px;
    flex: 0 0 calc(33.33% - 20px);
    max-width: calc(33.33% - 20px);
    width:100%;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
    transition: .3s;
    border: 2px solid transparent;
    box-shadow: 0 6px 40px black;
    -webkit-filter: drop-shadow(0 6px 20px rgba(51, 99, 188, 0.017));
    filter: drop-shadow(0 6px 20px rgba(0, 89, 255, 0.017));
    border-radius: 10px;
    overflow: hidden;
    text-decoration: none;
    padding:20px;
}

/* 
display: flex;
    flex-flow: column;
    width: 100%;
    box-shadow: 0 6px 35px black;
    -webkit-filter: drop-shadow(0 6px 20px rgba(51, 99, 188, 0.017));
    filter: drop-shadow(0 6px 20px rgba(0, 89, 255, 0.017));
    border-radius: 10px;
    overflow: hidden;
    text-decoration: none;
*/


.price__card:hover {
    border-color: #E40045;
    
}


.price__h5 {
    color: white;
}

.price__btn {
    border:none;
    outline:none;
    background: #E40045;
    color: white;
    border-radius: 10px;
    max-width: 120px;
    width:100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    cursor: pointer;
    transition: .3s;
    border: 1px solid #E40045;
}

.price__btn:hover{
    background-color: #6f0323;
}

@media(max-width:969px) {
    .price__section {
        padding:20px 0px;
    }
    .price__h1 {
        font-size:30px;
        line-height: 40px;
    }
    .price__card {
        flex:0 0 calc(50% - 20px);
        max-width:calc(50% - 20px);
    }
}

@media(max-width:600px) {
    .price__card {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

.text__h3 {
    color: white;
    cursor: pointer;
    text-align: center;
}

.link__pointer{
    

    /* display: inline; */
    text-align: center;
    justify-content: center;
    align-items: center;
    padding:20px;
}