/* Общие стили для всех кнопок */
.btn-different{
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
    font-family: 'Roboto';
}
.slide-btns {
    font-size: 'Roboto';
	position: relative;
    color: white;
    font-size: 25px;
    border-radius: 25px;
    text-transform:  uppercase;
    background: #ec004b;
    border: 1px solid #7b1e3a2d;
    padding: 10px 30px;
    margin: 15px;  
    z-index: 1;
    overflow: hidden;
    cursor: pointer;
}
.slide-btns:hover {
  color: #d60048;
  text-decoration: none;
}
.slide-btns::after {
  content: "";
  
  position: absolute;
  z-index: -1;

  display: block;
}
.slide-btns[class^="slide"]::after {
  transition: all 0.20s;background:#49052F
}
.slide-btns[class^="slide"]:hover::after {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: all 0.60s;
}
/* Button 8 Перекрывается диагонально налево */
.slide-btns.slide-d-left::after {
    left: -20%;
    right: -20%;
    top: 0;
    bottom: 0;
    transform: skewX(45deg) scale(0, 1);
    cursor: pointer;
  }
  .slide-btns.slide-d-left:hover::after {
    transform: skewX(45deg) scale(2, 2);
  }
  