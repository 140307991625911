.price__btn {
    border:none;
    outline:none;
    background: #E40045;
    color: white;
    /* border-radius: 10px; */
    max-width: 200px;
    width:100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    cursor: pointer;
    transition: .3s;
    /* border: 1px solid #E40045; */
    
}

.price__btn:hover{
    background-color: #6f0323;
}