#DIV_1 {
    align-items: center;
    block-size: 80px;
    border-block-end-color: rgb(51, 51, 51);
    border-block-start-color: rgb(51, 51, 51);
    border-inline-end-color: rgb(51, 51, 51);
    border-inline-start-color: rgb(51, 51, 51);
    bottom: 0px;
    box-sizing: border-box;
    caret-color: rgb(51, 51, 51);
    color: rgb(51, 51, 51);
    column-rule-color: rgb(51, 51, 51);
    display: flex;
    height: 80px;
    inline-size: 350.4px;
    inset-block-end: 0px;
    inset-block-start: 0px;
    inset-inline-end: 0px;
    inset-inline-start: 0px;
    justify-content: center;
    left: 0px;
    min-block-size: auto;
    min-height: auto;
    min-inline-size: auto;
    min-width: auto;
    overflow-wrap: break-word;
    perspective-origin: 175.2px 40px;
    position: relative;
    right: 0px;
    text-align: left;
    text-decoration: none solid rgb(51, 51, 51);
    text-emphasis-color: rgb(51, 51, 51);
    text-size-adjust: 100%;
    top: 0px;
    transform-origin: 175.2px 40px;
    width: 350.4px;
    border: 0px none rgb(51, 51, 51);
    flex: 0 0 auto;
    font: 16px Montserrat, sans-serif;
    outline: rgb(51, 51, 51) none 0px;
}/*#DIV_1*/

#DIV_1:after {
    border-block-end-color: rgb(51, 51, 51);
    border-block-start-color: rgb(51, 51, 51);
    border-inline-end-color: rgb(51, 51, 51);
    border-inline-start-color: rgb(51, 51, 51);
    caret-color: rgb(51, 51, 51);
    color: rgb(51, 51, 51);
    column-rule-color: rgb(51, 51, 51);
    display: block;
    overflow-wrap: break-word;
    text-align: left;
    text-decoration: none solid rgb(51, 51, 51);
    text-emphasis-color: rgb(51, 51, 51);
    text-size-adjust: 100%;
    border: 0px none rgb(51, 51, 51);
    font: 16px Montserrat, sans-serif;
    outline: rgb(51, 51, 51) none 0px;
}/*#DIV_1:after*/

#DIV_1:before {
    border-block-end-color: rgb(51, 51, 51);
    border-block-start-color: rgb(51, 51, 51);
    border-inline-end-color: rgb(51, 51, 51);
    border-inline-start-color: rgb(51, 51, 51);
    caret-color: rgb(51, 51, 51);
    color: rgb(51, 51, 51);
    column-rule-color: rgb(51, 51, 51);
    display: block;
    overflow-wrap: break-word;
    text-align: left;
    text-decoration: none solid rgb(51, 51, 51);
    text-emphasis-color: rgb(51, 51, 51);
    text-size-adjust: 100%;
    border: 0px none rgb(51, 51, 51);
    font: 16px Montserrat, sans-serif;
    outline: rgb(51, 51, 51) none 0px;
}/*#DIV_1:before*/

#DIV_2 {
    align-items: center;
    block-size: 80px;
    border-block-end-color: rgb(51, 51, 51);
    border-block-start-color: rgb(51, 51, 51);
    border-inline-end-color: rgb(51, 51, 51);
    border-inline-start-color: rgb(51, 51, 51);
    bottom: 0px;
    box-sizing: border-box;
    caret-color: rgb(51, 51, 51);
    color: rgb(51, 51, 51);
    column-rule-color: rgb(51, 51, 51);
    display: flex;
    height: 80px;
    inline-size: 350.4px;
    inset-block-end: 0px;
    inset-block-start: 0px;
    inset-inline-end: 0px;
    inset-inline-start: 0px;
    justify-content: center;
    left: 0px;
    min-block-size: auto;
    min-height: auto;
    min-inline-size: auto;
    min-width: auto;
    overflow-wrap: break-word;
    padding-block-end: 10px;
    padding-block-start: 10px;
    padding-inline-end: 10px;
    padding-inline-start: 10px;
    perspective-origin: 175.2px 40px;
    position: relative;
    right: 0px;
    text-align: left;
    text-decoration: none solid rgb(51, 51, 51);
    text-emphasis-color: rgb(51, 51, 51);
    text-size-adjust: 100%;
    top: 0px;
    transform-origin: 175.2px 40px;
    width: 350.4px;
    background: rgba(189, 16, 224, 0) none repeat scroll 0% 0% / auto padding-box border-box;
    border: 0px none rgb(51, 51, 51);
    font: 16px Montserrat, sans-serif;
    outline: rgb(51, 51, 51) none 0px;
    padding: 10px;
}/*#DIV_2*/

#DIV_2:after {
    border-block-end-color: rgb(51, 51, 51);
    border-block-start-color: rgb(51, 51, 51);
    border-inline-end-color: rgb(51, 51, 51);
    border-inline-start-color: rgb(51, 51, 51);
    caret-color: rgb(51, 51, 51);
    color: rgb(51, 51, 51);
    column-rule-color: rgb(51, 51, 51);
    display: block;
    overflow-wrap: break-word;
    text-align: left;
    text-decoration: none solid rgb(51, 51, 51);
    text-emphasis-color: rgb(51, 51, 51);
    text-size-adjust: 100%;
    border: 0px none rgb(51, 51, 51);
    font: 16px Montserrat, sans-serif;
    outline: rgb(51, 51, 51) none 0px;
}/*#DIV_2:after*/

#DIV_2:before {
    border-block-end-color: rgb(51, 51, 51);
    border-block-start-color: rgb(51, 51, 51);
    border-inline-end-color: rgb(51, 51, 51);
    border-inline-start-color: rgb(51, 51, 51);
    caret-color: rgb(51, 51, 51);
    color: rgb(51, 51, 51);
    column-rule-color: rgb(51, 51, 51);
    display: block;
    overflow-wrap: break-word;
    text-align: left;
    text-decoration: none solid rgb(51, 51, 51);
    text-emphasis-color: rgb(51, 51, 51);
    text-size-adjust: 100%;
    border: 0px none rgb(51, 51, 51);
    font: 16px Montserrat, sans-serif;
    outline: rgb(51, 51, 51) none 0px;
}/*#DIV_2:before*/

#DIV_3 {
    align-items: center;
    block-size: 60px;
    border-block-end-color: rgb(51, 51, 51);
    border-block-start-color: rgb(51, 51, 51);
    border-inline-end-color: rgb(51, 51, 51);
    border-inline-start-color: rgb(51, 51, 51);
    bottom: 0px;
    box-sizing: border-box;
    caret-color: rgb(51, 51, 51);
    color: rgb(51, 51, 51);
    column-rule-color: rgb(51, 51, 51);
    display: flex;
    height: 60px;
    inline-size: 330.4px;
    inset-block-end: 0px;
    inset-block-start: 0px;
    inset-inline-end: 0px;
    inset-inline-start: 0px;
    justify-content: center;
    left: 0px;
    min-block-size: auto;
    min-height: auto;
    overflow-wrap: break-word;
    perspective-origin: 165.2px 30px;
    position: relative;
    right: 0px;
    text-align: center;
    text-decoration: none solid rgb(51, 51, 51);
    text-emphasis-color: rgb(51, 51, 51);
    text-size-adjust: 100%;
    top: 0px;
    transform-origin: 165.2px 30px;
    vertical-align: top;
    width: 330.4px;
    border: 0px none rgb(51, 51, 51);
    font: 14px Montserrat, sans-serif;
    outline: rgb(51, 51, 51) none 0px;
}/*#DIV_3*/

#DIV_3:after {
    border-block-end-color: rgb(51, 51, 51);
    border-block-start-color: rgb(51, 51, 51);
    border-inline-end-color: rgb(51, 51, 51);
    border-inline-start-color: rgb(51, 51, 51);
    caret-color: rgb(51, 51, 51);
    color: rgb(51, 51, 51);
    column-rule-color: rgb(51, 51, 51);
    display: block;
    overflow-wrap: break-word;
    text-align: center;
    text-decoration: none solid rgb(51, 51, 51);
    text-emphasis-color: rgb(51, 51, 51);
    text-size-adjust: 100%;
    border: 0px none rgb(51, 51, 51);
    font: 14px Montserrat, sans-serif;
    outline: rgb(51, 51, 51) none 0px;
}/*#DIV_3:after*/

#DIV_3:before {
    border-block-end-color: rgb(51, 51, 51);
    border-block-start-color: rgb(51, 51, 51);
    border-inline-end-color: rgb(51, 51, 51);
    border-inline-start-color: rgb(51, 51, 51);
    caret-color: rgb(51, 51, 51);
    color: rgb(51, 51, 51);
    column-rule-color: rgb(51, 51, 51);
    display: block;
    overflow-wrap: break-word;
    text-align: center;
    text-decoration: none solid rgb(51, 51, 51);
    text-emphasis-color: rgb(51, 51, 51);
    text-size-adjust: 100%;
    border: 0px none rgb(51, 51, 51);
    font: 14px Montserrat, sans-serif;
    outline: rgb(51, 51, 51) none 0px;
}/*#DIV_3:before*/

#DIV_4 {
    block-size: 60px;
    border-block-end-color: rgb(51, 51, 51);
    border-block-start-color: rgb(51, 51, 51);
    border-inline-end-color: rgb(51, 51, 51);
    border-inline-start-color: rgb(51, 51, 51);
    box-sizing: border-box;
    caret-color: rgb(51, 51, 51);
    color: rgb(51, 51, 51);
    column-rule-color: rgb(51, 51, 51);
    height: 60px;
    inline-size: 330.4px;
    min-block-size: auto;
    min-height: auto;
    min-inline-size: auto;
    min-width: auto;
    overflow-wrap: break-word;
    perspective-origin: 165.2px 30px;
    text-align: center;
    text-decoration: none solid rgb(51, 51, 51);
    text-emphasis-color: rgb(51, 51, 51);
    text-size-adjust: 100%;
    transform-origin: 165.2px 30px;
    width: 330.4px;
    will-change: transform;
    border: 0px none rgb(51, 51, 51);
    font: 14px Montserrat, sans-serif;
    outline: rgb(51, 51, 51) none 0px;
}/*#DIV_4*/

#DIV_4:after {
    border-block-end-color: rgb(51, 51, 51);
    border-block-start-color: rgb(51, 51, 51);
    border-inline-end-color: rgb(51, 51, 51);
    border-inline-start-color: rgb(51, 51, 51);
    caret-color: rgb(51, 51, 51);
    color: rgb(51, 51, 51);
    column-rule-color: rgb(51, 51, 51);
    overflow-wrap: break-word;
    text-align: center;
    text-decoration: none solid rgb(51, 51, 51);
    text-emphasis-color: rgb(51, 51, 51);
    text-size-adjust: 100%;
    border: 0px none rgb(51, 51, 51);
    font: 14px Montserrat, sans-serif;
    outline: rgb(51, 51, 51) none 0px;
}/*#DIV_4:after*/

#DIV_4:before {
    border-block-end-color: rgb(51, 51, 51);
    border-block-start-color: rgb(51, 51, 51);
    border-inline-end-color: rgb(51, 51, 51);
    border-inline-start-color: rgb(51, 51, 51);
    caret-color: rgb(51, 51, 51);
    color: rgb(51, 51, 51);
    column-rule-color: rgb(51, 51, 51);
    overflow-wrap: break-word;
    text-align: center;
    text-decoration: none solid rgb(51, 51, 51);
    text-emphasis-color: rgb(51, 51, 51);
    text-size-adjust: 100%;
    border: 0px none rgb(51, 51, 51);
    font: 14px Montserrat, sans-serif;
    outline: rgb(51, 51, 51) none 0px;
}/*#DIV_4:before*/

#DIV_5 {
    block-size: 60px;
    border-block-end-color: rgb(51, 51, 51);
    border-block-start-color: rgb(51, 51, 51);
    border-inline-end-color: rgb(51, 51, 51);
    border-inline-start-color: rgb(51, 51, 51);
    box-sizing: border-box;
    caret-color: rgb(51, 51, 51);
    color: rgb(51, 51, 51);
    column-rule-color: rgb(51, 51, 51);
    display: flex;
    height: 60px;
    inline-size: 330.4px;
    justify-content: center;
    overflow-wrap: break-word;
    perspective-origin: 165.2px 30px;
    text-align: center;
    text-decoration: none solid rgb(51, 51, 51);
    text-emphasis-color: rgb(51, 51, 51);
    text-size-adjust: 100%;
    transform-origin: 165.2px 30px;
    width: 330.4px;
    border: 0px none rgb(51, 51, 51);
    flex-flow: row wrap;
    font: 14px Montserrat, sans-serif;
    outline: rgb(51, 51, 51) none 0px;
}/*#DIV_5*/

#DIV_5:after {
    border-block-end-color: rgb(51, 51, 51);
    border-block-start-color: rgb(51, 51, 51);
    border-inline-end-color: rgb(51, 51, 51);
    border-inline-start-color: rgb(51, 51, 51);
    caret-color: rgb(51, 51, 51);
    color: rgb(51, 51, 51);
    column-rule-color: rgb(51, 51, 51);
    display: block;
    overflow-wrap: break-word;
    text-align: center;
    text-decoration: none solid rgb(51, 51, 51);
    text-emphasis-color: rgb(51, 51, 51);
    text-size-adjust: 100%;
    border: 0px none rgb(51, 51, 51);
    font: 14px Montserrat, sans-serif;
    outline: rgb(51, 51, 51) none 0px;
}/*#DIV_5:after*/

#DIV_5:before {
    border-block-end-color: rgb(51, 51, 51);
    border-block-start-color: rgb(51, 51, 51);
    border-inline-end-color: rgb(51, 51, 51);
    border-inline-start-color: rgb(51, 51, 51);
    caret-color: rgb(51, 51, 51);
    color: rgb(51, 51, 51);
    column-rule-color: rgb(51, 51, 51);
    display: block;
    overflow-wrap: break-word;
    text-align: center;
    text-decoration: none solid rgb(51, 51, 51);
    text-emphasis-color: rgb(51, 51, 51);
    text-size-adjust: 100%;
    border: 0px none rgb(51, 51, 51);
    font: 14px Montserrat, sans-serif;
    outline: rgb(51, 51, 51) none 0px;
}/*#DIV_5:before*/

#DIV_6, #DIV_21, #DIV_36, #DIV_47 {
    block-size: 60px;
    border-block-end-color: rgb(51, 51, 51);
    border-block-start-color: rgb(51, 51, 51);
    border-inline-end-color: rgb(51, 51, 51);
    border-inline-start-color: rgb(51, 51, 51);
    box-sizing: border-box;
    caret-color: rgb(51, 51, 51);
    color: rgb(51, 51, 51);
    column-rule-color: rgb(51, 51, 51);
    height: 60px;
    inline-size: 82.6px;
    max-inline-size: 100%;
    max-width: 100%;
    min-block-size: auto;
    min-height: auto;
    min-inline-size: auto;
    min-width: auto;
    overflow-wrap: break-word;
    perspective-origin: 41.3px 30px;
    text-align: center;
    text-decoration: none solid rgb(51, 51, 51);
    text-emphasis-color: rgb(51, 51, 51);
    text-size-adjust: 100%;
    transform-origin: 41.3px 30px;
    width: 82.6px;
    border: 0px none rgb(51, 51, 51);
    flex: 0 0 auto;
    font: 14px Montserrat, sans-serif;
    outline: rgb(51, 51, 51) none 0px;
}/*#DIV_6, #DIV_21, #DIV_36, #DIV_47*/

#DIV_6:after, #DIV_21:after, #DIV_36:after, #DIV_47:after {
    border-block-end-color: rgb(51, 51, 51);
    border-block-start-color: rgb(51, 51, 51);
    border-inline-end-color: rgb(51, 51, 51);
    border-inline-start-color: rgb(51, 51, 51);
    caret-color: rgb(51, 51, 51);
    color: rgb(51, 51, 51);
    column-rule-color: rgb(51, 51, 51);
    overflow-wrap: break-word;
    text-align: center;
    text-decoration: none solid rgb(51, 51, 51);
    text-emphasis-color: rgb(51, 51, 51);
    text-size-adjust: 100%;
    border: 0px none rgb(51, 51, 51);
    font: 14px Montserrat, sans-serif;
    outline: rgb(51, 51, 51) none 0px;
}/*#DIV_6:after, #DIV_21:after, #DIV_36:after, #DIV_47:after*/

#DIV_6:before, #DIV_21:before, #DIV_36:before, #DIV_47:before {
    border-block-end-color: rgb(51, 51, 51);
    border-block-start-color: rgb(51, 51, 51);
    border-inline-end-color: rgb(51, 51, 51);
    border-inline-start-color: rgb(51, 51, 51);
    caret-color: rgb(51, 51, 51);
    color: rgb(51, 51, 51);
    column-rule-color: rgb(51, 51, 51);
    overflow-wrap: break-word;
    text-align: center;
    text-decoration: none solid rgb(51, 51, 51);
    text-emphasis-color: rgb(51, 51, 51);
    text-size-adjust: 100%;
    border: 0px none rgb(51, 51, 51);
    font: 14px Montserrat, sans-serif;
    outline: rgb(51, 51, 51) none 0px;
}/*#DIV_6:before, #DIV_21:before, #DIV_36:before, #DIV_47:before*/

#DIV_7, #DIV_22, #DIV_37, #DIV_48 {
    align-items: center;
    block-size: 40px;
    border-block-end-color: rgba(31, 26, 22, 0.1);
    border-block-end-style: solid;
    border-block-end-width: 1.6px;
    border-block-start-color: rgba(31, 26, 22, 0.1);
    border-block-start-style: solid;
    border-block-start-width: 1.6px;
    border-end-end-radius: 250px;
    border-end-start-radius: 250px;
    border-inline-end-color: rgba(31, 26, 22, 0.1);
    border-inline-end-style: solid;
    border-inline-end-width: 1.6px;
    border-inline-start-color: rgba(31, 26, 22, 0.1);
    border-inline-start-style: solid;
    border-inline-start-width: 1.6px;
    border-start-end-radius: 250px;
    border-start-start-radius: 250px;
    box-sizing: border-box;
    caret-color: rgb(51, 51, 51);
    color: rgb(51, 51, 51);
    column-rule-color: rgb(51, 51, 51);
    display: flex;
    height: 40px;
    inline-size: 40px;
    justify-content: center;
    margin-block-end: 10px;
    margin-block-start: 10px;
    margin-inline-end: 21.3px;
    margin-inline-start: 21.3px;
    max-inline-size: 100%;
    max-width: 100%;
    opacity: 0.8;
    overflow-wrap: break-word;
    perspective-origin: 20px 20px;
    text-align: center;
    text-decoration: none solid rgb(51, 51, 51);
    text-emphasis-color: rgb(51, 51, 51);
    text-size-adjust: 100%;
    transform-origin: 20px 20px;
    width: 40px;
    border: 1.6px solid rgba(31, 26, 22, 0.1);
    border-radius: 250px;
    flex: 1 1 auto;
    flex-flow: column nowrap;
    font: 14px Montserrat, sans-serif;
    margin: 10px 21.3px;
    outline: rgb(51, 51, 51) none 0px;
}/*#DIV_7, #DIV_22, #DIV_37, #DIV_48*/

#DIV_7:after, #DIV_22:after, #DIV_37:after, #DIV_48:after {
    border-block-end-color: rgb(51, 51, 51);
    border-block-start-color: rgb(51, 51, 51);
    border-inline-end-color: rgb(51, 51, 51);
    border-inline-start-color: rgb(51, 51, 51);
    caret-color: rgb(51, 51, 51);
    color: rgb(51, 51, 51);
    column-rule-color: rgb(51, 51, 51);
    display: block;
    overflow-wrap: break-word;
    text-align: center;
    text-decoration: none solid rgb(51, 51, 51);
    text-emphasis-color: rgb(51, 51, 51);
    text-size-adjust: 100%;
    border: 0px none rgb(51, 51, 51);
    font: 14px Montserrat, sans-serif;
    outline: rgb(51, 51, 51) none 0px;
}/*#DIV_7:after, #DIV_22:after, #DIV_37:after, #DIV_48:after*/

#DIV_7:before, #DIV_22:before, #DIV_37:before, #DIV_48:before {
    border-block-end-color: rgb(51, 51, 51);
    border-block-start-color: rgb(51, 51, 51);
    border-inline-end-color: rgb(51, 51, 51);
    border-inline-start-color: rgb(51, 51, 51);
    caret-color: rgb(51, 51, 51);
    color: rgb(51, 51, 51);
    column-rule-color: rgb(51, 51, 51);
    display: block;
    overflow-wrap: break-word;
    text-align: center;
    text-decoration: none solid rgb(51, 51, 51);
    text-emphasis-color: rgb(51, 51, 51);
    text-size-adjust: 100%;
    border: 0px none rgb(51, 51, 51);
    font: 14px Montserrat, sans-serif;
    outline: rgb(51, 51, 51) none 0px;
}/*#DIV_7:before, #DIV_22:before, #DIV_37:before, #DIV_48:before*/

#A_8, #A_23, #A_38, #A_49 {
    align-items: center;
    block-size: 40px;
    box-sizing: border-box;
    display: flex;
    height: 40px;
    inline-size: 40px;
    justify-content: center;
    min-block-size: auto;
    min-height: auto;
    min-inline-size: auto;
    min-width: auto;
    overflow-wrap: break-word;
    perspective-origin: 20px 20px;
    text-align: center;
    text-decoration: none solid rgb(0, 0, 238);
    text-size-adjust: 100%;
    transform-origin: 20px 20px;
    width: 40px;
    flex: 0 0 auto;
    font: 14px Montserrat, sans-serif;
    transition: all 0.3s ease 0s;
}/*#A_8, #A_23, #A_38, #A_49*/

#A_8:after, #A_23:after, #A_38:after, #A_49:after {
    display: block;
    overflow-wrap: break-word;
    text-align: center;
    text-size-adjust: 100%;
    font: 14px Montserrat, sans-serif;
}/*#A_8:after, #A_23:after, #A_38:after, #A_49:after*/

#A_8:before, #A_23:before, #A_38:before, #A_49:before {
    display: block;
    overflow-wrap: break-word;
    text-align: center;
    text-size-adjust: 100%;
    font: 14px Montserrat, sans-serif;
}/*#A_8:before, #A_23:before, #A_38:before, #A_49:before*/

#SPAN_9, #SPAN_24, #SPAN_39, #SPAN_50 {
    align-items: center;
    block-size: 40px;
    border-block-end-color: rgb(0, 0, 238);
    border-block-start-color: rgb(0, 0, 238);
    border-end-end-radius: 250px;
    border-end-start-radius: 250px;
    border-inline-end-color: rgb(0, 0, 238);
    border-inline-start-color: rgb(0, 0, 238);
    border-start-end-radius: 250px;
    border-start-start-radius: 250px;
    bottom: 0px;
    box-sizing: border-box;
    caret-color: rgb(0, 0, 238);
    color: rgb(0, 0, 238);
    column-rule-color: rgb(0, 0, 238);
    cursor: pointer;
    display: flex;
    height: 40px;
    inline-size: 40px;
    inset-block-end: 0px;
    inset-block-start: 0px;
    inset-inline-end: 0px;
    inset-inline-start: 0px;
    justify-content: center;
    left: 0px;
    min-block-size: auto;
    min-height: auto;
    min-inline-size: auto;
    min-width: auto;
    overflow-wrap: break-word;
    perspective-origin: 20px 20px;
    position: relative;
    right: 0px;
    text-align: center;
    text-decoration: none solid rgb(0, 0, 238);
    text-emphasis-color: rgb(0, 0, 238);
    text-size-adjust: 100%;
    top: 0px;
    transform-origin: 20px 20px;
    vertical-align: top;
    width: 40px;
    border: 0px none rgb(0, 0, 238);
    border-radius: 250px;
    flex: 0 0 auto;
    font: 14px Montserrat, sans-serif;
    outline: rgb(0, 0, 238) none 0px;
    overflow: hidden;
}/*#SPAN_9, #SPAN_24, #SPAN_39, #SPAN_50*/

#SPAN_9:after, #SPAN_24:after, #SPAN_39:after, #SPAN_50:after {
    border-block-end-color: rgb(0, 0, 238);
    border-block-start-color: rgb(0, 0, 238);
    border-inline-end-color: rgb(0, 0, 238);
    border-inline-start-color: rgb(0, 0, 238);
    caret-color: rgb(0, 0, 238);
    color: rgb(0, 0, 238);
    column-rule-color: rgb(0, 0, 238);
    cursor: pointer;
    display: block;
    overflow-wrap: break-word;
    text-align: center;
    text-decoration: none solid rgb(0, 0, 238);
    text-emphasis-color: rgb(0, 0, 238);
    text-size-adjust: 100%;
    border: 0px none rgb(0, 0, 238);
    font: 14px Montserrat, sans-serif;
    outline: rgb(0, 0, 238) none 0px;
}/*#SPAN_9:after, #SPAN_24:after, #SPAN_39:after, #SPAN_50:after*/

#SPAN_9:before, #SPAN_24:before, #SPAN_39:before, #SPAN_50:before {
    border-block-end-color: rgb(0, 0, 238);
    border-block-start-color: rgb(0, 0, 238);
    border-inline-end-color: rgb(0, 0, 238);
    border-inline-start-color: rgb(0, 0, 238);
    caret-color: rgb(0, 0, 238);
    color: rgb(0, 0, 238);
    column-rule-color: rgb(0, 0, 238);
    cursor: pointer;
    display: block;
    overflow-wrap: break-word;
    text-align: center;
    text-decoration: none solid rgb(0, 0, 238);
    text-emphasis-color: rgb(0, 0, 238);
    text-size-adjust: 100%;
    border: 0px none rgb(0, 0, 238);
    font: 14px Montserrat, sans-serif;
    outline: rgb(0, 0, 238) none 0px;
}/*#SPAN_9:before, #SPAN_24:before, #SPAN_39:before, #SPAN_50:before*/

#svg_10, #svg_25, #svg_40, #svg_51 {
    block-size: 40px;
    border-block-end-color: rgb(0, 0, 238);
    border-block-start-color: rgb(0, 0, 238);
    border-inline-end-color: rgb(0, 0, 238);
    border-inline-start-color: rgb(0, 0, 238);
    box-sizing: border-box;
    caret-color: rgb(0, 0, 238);
    color: rgb(0, 0, 238);
    column-rule-color: rgb(0, 0, 238);
    cursor: pointer;
    display: block;
    fill: none;
    height: 40px;
    inline-size: 40px;
    min-block-size: auto;
    min-height: auto;
    min-inline-size: auto;
    min-width: auto;
    overflow-clip-margin: content-box;
    overflow-wrap: break-word;
    perspective-origin: 20px 20px;
    text-align: center;
    text-decoration: none solid rgb(0, 0, 238);
    text-emphasis-color: rgb(0, 0, 238);
    text-size-adjust: 100%;
    transform-origin: 20px 20px;
    width: 40px;
    border: 0px none rgb(0, 0, 238);
    flex: 0 0 auto;
    font: 14px Montserrat, sans-serif;
    outline: rgb(0, 0, 238) none 0px;
    overflow: hidden;
}/*#svg_10, #svg_25, #svg_40, #svg_51*/

#svg_10:after, #svg_25:after, #svg_40:after, #svg_51:after {
    border-block-end-color: rgb(0, 0, 238);
    border-block-start-color: rgb(0, 0, 238);
    border-inline-end-color: rgb(0, 0, 238);
    border-inline-start-color: rgb(0, 0, 238);
    caret-color: rgb(0, 0, 238);
    color: rgb(0, 0, 238);
    column-rule-color: rgb(0, 0, 238);
    cursor: pointer;
    fill: none;
    overflow-wrap: break-word;
    text-align: center;
    text-decoration: none solid rgb(0, 0, 238);
    text-emphasis-color: rgb(0, 0, 238);
    text-size-adjust: 100%;
    border: 0px none rgb(0, 0, 238);
    font: 14px Montserrat, sans-serif;
    outline: rgb(0, 0, 238) none 0px;
}/*#svg_10:after, #svg_25:after, #svg_40:after, #svg_51:after*/

#svg_10:before, #svg_25:before, #svg_40:before, #svg_51:before {
    border-block-end-color: rgb(0, 0, 238);
    border-block-start-color: rgb(0, 0, 238);
    border-inline-end-color: rgb(0, 0, 238);
    border-inline-start-color: rgb(0, 0, 238);
    caret-color: rgb(0, 0, 238);
    color: rgb(0, 0, 238);
    column-rule-color: rgb(0, 0, 238);
    cursor: pointer;
    fill: none;
    overflow-wrap: break-word;
    text-align: center;
    text-decoration: none solid rgb(0, 0, 238);
    text-emphasis-color: rgb(0, 0, 238);
    text-size-adjust: 100%;
    border: 0px none rgb(0, 0, 238);
    font: 14px Montserrat, sans-serif;
    outline: rgb(0, 0, 238) none 0px;
}/*#svg_10:before, #svg_25:before, #svg_40:before, #svg_51:before*/

#path_11 {
    border-block-end-color: rgb(0, 0, 238);
    border-block-start-color: rgb(0, 0, 238);
    border-inline-end-color: rgb(0, 0, 238);
    border-inline-start-color: rgb(0, 0, 238);
    box-sizing: border-box;
    caret-color: rgb(0, 0, 238);
    color: rgb(0, 0, 238);
    column-rule-color: rgb(0, 0, 238);
    cursor: pointer;
    d: path("M 0 0 H 64 V 64 H 0 Z");
    fill: url("#paint0_linear_217_1053");
    overflow-wrap: break-word;
    perspective-origin: 0px 0px;
    text-align: center;
    text-decoration: none solid rgb(0, 0, 238);
    text-emphasis-color: rgb(0, 0, 238);
    text-size-adjust: 100%;
    transform-origin: 0px 0px;
    border: 0px none rgb(0, 0, 238);
    font: 14px Montserrat, sans-serif;
    outline: rgb(0, 0, 238) none 0px;
}/*#path_11*/

#path_11:after {
    border-block-end-color: rgb(0, 0, 238);
    border-block-start-color: rgb(0, 0, 238);
    border-inline-end-color: rgb(0, 0, 238);
    border-inline-start-color: rgb(0, 0, 238);
    caret-color: rgb(0, 0, 238);
    color: rgb(0, 0, 238);
    column-rule-color: rgb(0, 0, 238);
    cursor: pointer;
    fill: url("#paint0_linear_217_1053");
    overflow-wrap: break-word;
    text-align: center;
    text-decoration: none solid rgb(0, 0, 238);
    text-emphasis-color: rgb(0, 0, 238);
    text-size-adjust: 100%;
    border: 0px none rgb(0, 0, 238);
    font: 14px Montserrat, sans-serif;
    outline: rgb(0, 0, 238) none 0px;
}/*#path_11:after*/

#path_11:before {
    border-block-end-color: rgb(0, 0, 238);
    border-block-start-color: rgb(0, 0, 238);
    border-inline-end-color: rgb(0, 0, 238);
    border-inline-start-color: rgb(0, 0, 238);
    caret-color: rgb(0, 0, 238);
    color: rgb(0, 0, 238);
    column-rule-color: rgb(0, 0, 238);
    cursor: pointer;
    fill: url("#paint0_linear_217_1053");
    overflow-wrap: break-word;
    text-align: center;
    text-decoration: none solid rgb(0, 0, 238);
    text-emphasis-color: rgb(0, 0, 238);
    text-size-adjust: 100%;
    border: 0px none rgb(0, 0, 238);
    font: 14px Montserrat, sans-serif;
    outline: rgb(0, 0, 238) none 0px;
}/*#path_11:before*/

#path_12 {
    border-block-end-color: rgb(0, 0, 238);
    border-block-start-color: rgb(0, 0, 238);
    border-inline-end-color: rgb(0, 0, 238);
    border-inline-start-color: rgb(0, 0, 238);
    box-sizing: border-box;
    caret-color: rgb(0, 0, 238);
    clip-rule: evenodd;
    color: rgb(0, 0, 238);
    column-rule-color: rgb(0, 0, 238);
    cursor: pointer;
    d: path("M 19.604 31.3 C 26.761 28.05 31.524 25.89 33.917 24.841 C 40.727 21.868 42.157 21.355 43.078 21.333 C 43.282 21.333 43.732 21.376 44.039 21.633 C 44.284 21.847 44.346 22.125 44.387 22.339 C 44.427 22.552 44.468 23.002 44.427 23.344 C 44.06 27.408 42.465 37.269 41.647 41.804 C 41.299 43.728 40.624 44.37 39.97 44.434 C 38.539 44.563 37.455 43.45 36.085 42.509 C 33.917 41.033 32.711 40.114 30.605 38.659 C 28.171 36.991 29.745 36.071 31.136 34.574 C 31.504 34.189 37.782 28.199 37.905 27.664 C 37.925 27.6 37.925 27.344 37.782 27.215 C 37.639 27.087 37.434 27.13 37.271 27.173 C 37.046 27.216 33.611 29.611 26.924 34.338 C 25.942 35.044 25.064 35.386 24.266 35.365 C 23.386 35.344 21.71 34.852 20.442 34.424 C 18.908 33.91 17.682 33.632 17.784 32.734 C 17.845 32.264 18.459 31.793 19.604 31.301 Z");
    fill: rgb(255, 255, 255);
    fill-rule: evenodd;
    overflow-wrap: break-word;
    perspective-origin: 0px 0px;
    text-align: center;
    text-decoration: none solid rgb(0, 0, 238);
    text-emphasis-color: rgb(0, 0, 238);
    text-size-adjust: 100%;
    transform-origin: 0px 0px;
    border: 0px none rgb(0, 0, 238);
    font: 14px Montserrat, sans-serif;
    outline: rgb(0, 0, 238) none 0px;
}/*#path_12*/

#path_12:after {
    border-block-end-color: rgb(0, 0, 238);
    border-block-start-color: rgb(0, 0, 238);
    border-inline-end-color: rgb(0, 0, 238);
    border-inline-start-color: rgb(0, 0, 238);
    caret-color: rgb(0, 0, 238);
    clip-rule: evenodd;
    color: rgb(0, 0, 238);
    column-rule-color: rgb(0, 0, 238);
    cursor: pointer;
    fill: rgb(255, 255, 255);
    fill-rule: evenodd;
    overflow-wrap: break-word;
    text-align: center;
    text-decoration: none solid rgb(0, 0, 238);
    text-emphasis-color: rgb(0, 0, 238);
    text-size-adjust: 100%;
    border: 0px none rgb(0, 0, 238);
    font: 14px Montserrat, sans-serif;
    outline: rgb(0, 0, 238) none 0px;
}/*#path_12:after*/

#path_12:before {
    border-block-end-color: rgb(0, 0, 238);
    border-block-start-color: rgb(0, 0, 238);
    border-inline-end-color: rgb(0, 0, 238);
    border-inline-start-color: rgb(0, 0, 238);
    caret-color: rgb(0, 0, 238);
    clip-rule: evenodd;
    color: rgb(0, 0, 238);
    column-rule-color: rgb(0, 0, 238);
    cursor: pointer;
    fill: rgb(255, 255, 255);
    fill-rule: evenodd;
    overflow-wrap: break-word;
    text-align: center;
    text-decoration: none solid rgb(0, 0, 238);
    text-emphasis-color: rgb(0, 0, 238);
    text-size-adjust: 100%;
    border: 0px none rgb(0, 0, 238);
    font: 14px Montserrat, sans-serif;
    outline: rgb(0, 0, 238) none 0px;
}/*#path_12:before*/

#defs_13, #linearGradient_14, #defs_28 {
    border-block-end-color: rgb(0, 0, 238);
    border-block-start-color: rgb(0, 0, 238);
    border-inline-end-color: rgb(0, 0, 238);
    border-inline-start-color: rgb(0, 0, 238);
    box-sizing: border-box;
    caret-color: rgb(0, 0, 238);
    color: rgb(0, 0, 238);
    column-rule-color: rgb(0, 0, 238);
    cursor: pointer;
    fill: none;
    overflow-wrap: break-word;
    perspective-origin: 0px 0px;
    text-align: center;
    text-decoration: none solid rgb(0, 0, 238);
    text-emphasis-color: rgb(0, 0, 238);
    text-size-adjust: 100%;
    transform-origin: 0px 0px;
    border: 0px none rgb(0, 0, 238);
    font: 14px Montserrat, sans-serif;
    outline: rgb(0, 0, 238) none 0px;
}/*#defs_13, #linearGradient_14, #defs_28*/

#defs_13:after, #linearGradient_14:after, #defs_28:after {
    border-block-end-color: rgb(0, 0, 238);
    border-block-start-color: rgb(0, 0, 238);
    border-inline-end-color: rgb(0, 0, 238);
    border-inline-start-color: rgb(0, 0, 238);
    caret-color: rgb(0, 0, 238);
    color: rgb(0, 0, 238);
    column-rule-color: rgb(0, 0, 238);
    cursor: pointer;
    fill: none;
    overflow-wrap: break-word;
    text-align: center;
    text-decoration: none solid rgb(0, 0, 238);
    text-emphasis-color: rgb(0, 0, 238);
    text-size-adjust: 100%;
    border: 0px none rgb(0, 0, 238);
    font: 14px Montserrat, sans-serif;
    outline: rgb(0, 0, 238) none 0px;
}/*#defs_13:after, #linearGradient_14:after, #defs_28:after*/

#defs_13:before, #linearGradient_14:before, #defs_28:before {
    border-block-end-color: rgb(0, 0, 238);
    border-block-start-color: rgb(0, 0, 238);
    border-inline-end-color: rgb(0, 0, 238);
    border-inline-start-color: rgb(0, 0, 238);
    caret-color: rgb(0, 0, 238);
    color: rgb(0, 0, 238);
    column-rule-color: rgb(0, 0, 238);
    cursor: pointer;
    fill: none;
    overflow-wrap: break-word;
    text-align: center;
    text-decoration: none solid rgb(0, 0, 238);
    text-emphasis-color: rgb(0, 0, 238);
    text-size-adjust: 100%;
    border: 0px none rgb(0, 0, 238);
    font: 14px Montserrat, sans-serif;
    outline: rgb(0, 0, 238) none 0px;
}/*#defs_13:before, #linearGradient_14:before, #defs_28:before*/

#stop_15 {
    border-block-end-color: rgb(0, 0, 238);
    border-block-start-color: rgb(0, 0, 238);
    border-inline-end-color: rgb(0, 0, 238);
    border-inline-start-color: rgb(0, 0, 238);
    box-sizing: border-box;
    caret-color: rgb(0, 0, 238);
    color: rgb(0, 0, 238);
    column-rule-color: rgb(0, 0, 238);
    cursor: pointer;
    fill: none;
    overflow-wrap: break-word;
    stop-color: rgb(42, 171, 238);
    text-align: center;
    text-decoration: none solid rgb(0, 0, 238);
    text-emphasis-color: rgb(0, 0, 238);
    text-size-adjust: 100%;
    transform-origin: 0px 0px;
    border: 0px none rgb(0, 0, 238);
    font: 14px Montserrat, sans-serif;
    outline: rgb(0, 0, 238) none 0px;
}/*#stop_15*/

#stop_15:after {
    border-block-end-color: rgb(0, 0, 238);
    border-block-start-color: rgb(0, 0, 238);
    border-inline-end-color: rgb(0, 0, 238);
    border-inline-start-color: rgb(0, 0, 238);
    caret-color: rgb(0, 0, 238);
    color: rgb(0, 0, 238);
    column-rule-color: rgb(0, 0, 238);
    cursor: pointer;
    fill: none;
    overflow-wrap: break-word;
    text-align: center;
    text-decoration: none solid rgb(0, 0, 238);
    text-emphasis-color: rgb(0, 0, 238);
    text-size-adjust: 100%;
    border: 0px none rgb(0, 0, 238);
    font: 14px Montserrat, sans-serif;
    outline: rgb(0, 0, 238) none 0px;
}/*#stop_15:after*/

#stop_15:before {
    border-block-end-color: rgb(0, 0, 238);
    border-block-start-color: rgb(0, 0, 238);
    border-inline-end-color: rgb(0, 0, 238);
    border-inline-start-color: rgb(0, 0, 238);
    caret-color: rgb(0, 0, 238);
    color: rgb(0, 0, 238);
    column-rule-color: rgb(0, 0, 238);
    cursor: pointer;
    fill: none;
    overflow-wrap: break-word;
    text-align: center;
    text-decoration: none solid rgb(0, 0, 238);
    text-emphasis-color: rgb(0, 0, 238);
    text-size-adjust: 100%;
    border: 0px none rgb(0, 0, 238);
    font: 14px Montserrat, sans-serif;
    outline: rgb(0, 0, 238) none 0px;
}/*#stop_15:before*/

#stop_16 {
    border-block-end-color: rgb(0, 0, 238);
    border-block-start-color: rgb(0, 0, 238);
    border-inline-end-color: rgb(0, 0, 238);
    border-inline-start-color: rgb(0, 0, 238);
    box-sizing: border-box;
    caret-color: rgb(0, 0, 238);
    color: rgb(0, 0, 238);
    column-rule-color: rgb(0, 0, 238);
    cursor: pointer;
    fill: none;
    overflow-wrap: break-word;
    stop-color: rgb(34, 158, 217);
    text-align: center;
    text-decoration: none solid rgb(0, 0, 238);
    text-emphasis-color: rgb(0, 0, 238);
    text-size-adjust: 100%;
    transform-origin: 0px 0px;
    border: 0px none rgb(0, 0, 238);
    font: 14px Montserrat, sans-serif;
    outline: rgb(0, 0, 238) none 0px;
}/*#stop_16*/

#stop_16:after {
    border-block-end-color: rgb(0, 0, 238);
    border-block-start-color: rgb(0, 0, 238);
    border-inline-end-color: rgb(0, 0, 238);
    border-inline-start-color: rgb(0, 0, 238);
    caret-color: rgb(0, 0, 238);
    color: rgb(0, 0, 238);
    column-rule-color: rgb(0, 0, 238);
    cursor: pointer;
    fill: none;
    overflow-wrap: break-word;
    text-align: center;
    text-decoration: none solid rgb(0, 0, 238);
    text-emphasis-color: rgb(0, 0, 238);
    text-size-adjust: 100%;
    border: 0px none rgb(0, 0, 238);
    font: 14px Montserrat, sans-serif;
    outline: rgb(0, 0, 238) none 0px;
}/*#stop_16:after*/

#stop_16:before {
    border-block-end-color: rgb(0, 0, 238);
    border-block-start-color: rgb(0, 0, 238);
    border-inline-end-color: rgb(0, 0, 238);
    border-inline-start-color: rgb(0, 0, 238);
    caret-color: rgb(0, 0, 238);
    color: rgb(0, 0, 238);
    column-rule-color: rgb(0, 0, 238);
    cursor: pointer;
    fill: none;
    overflow-wrap: break-word;
    text-align: center;
    text-decoration: none solid rgb(0, 0, 238);
    text-emphasis-color: rgb(0, 0, 238);
    text-size-adjust: 100%;
    border: 0px none rgb(0, 0, 238);
    font: 14px Montserrat, sans-serif;
    outline: rgb(0, 0, 238) none 0px;
}/*#stop_16:before*/

#DIV_17, #DIV_32, #DIV_43, #DIV_54 {
    align-items: center;
    border-block-end-color: rgb(255, 255, 255);
    border-block-start-color: rgb(255, 255, 255);
    border-inline-end-color: rgb(255, 255, 255);
    border-inline-start-color: rgb(255, 255, 255);
    box-sizing: border-box;
    caret-color: rgb(255, 255, 255);
    color: rgb(255, 255, 255);
    column-rule-color: rgb(255, 255, 255);
    display: none;
    justify-content: flex-start;
    letter-spacing: 1px;
    margin-block-end: 20px;
    margin-block-start: 20px;
    margin-inline-start: 5px;
    overflow-wrap: break-word;
    text-align: left;
    text-decoration: none solid rgb(255, 255, 255);
    text-emphasis-color: rgb(255, 255, 255);
    text-size-adjust: 100%;
    border: 0px none rgb(255, 255, 255);
    font: 15px "Roboto Condensed", sans-serif;
    margin: 20px 0px 20px 5px;
    outline: rgb(255, 255, 255) none 0px;
}/*#DIV_17, #DIV_32, #DIV_43, #DIV_54*/

#DIV_17:after, #DIV_32:after, #DIV_43:after, #DIV_54:after {
    border-block-end-color: rgb(255, 255, 255);
    border-block-start-color: rgb(255, 255, 255);
    border-inline-end-color: rgb(255, 255, 255);
    border-inline-start-color: rgb(255, 255, 255);
    caret-color: rgb(255, 255, 255);
    color: rgb(255, 255, 255);
    column-rule-color: rgb(255, 255, 255);
    letter-spacing: 1px;
    overflow-wrap: break-word;
    text-align: left;
    text-decoration: none solid rgb(255, 255, 255);
    text-emphasis-color: rgb(255, 255, 255);
    text-size-adjust: 100%;
    border: 0px none rgb(255, 255, 255);
    font: 15px "Roboto Condensed", sans-serif;
    outline: rgb(255, 255, 255) none 0px;
}/*#DIV_17:after, #DIV_32:after, #DIV_43:after, #DIV_54:after*/

#DIV_17:before, #DIV_32:before, #DIV_43:before, #DIV_54:before {
    border-block-end-color: rgb(255, 255, 255);
    border-block-start-color: rgb(255, 255, 255);
    border-inline-end-color: rgb(255, 255, 255);
    border-inline-start-color: rgb(255, 255, 255);
    caret-color: rgb(255, 255, 255);
    color: rgb(255, 255, 255);
    column-rule-color: rgb(255, 255, 255);
    letter-spacing: 1px;
    overflow-wrap: break-word;
    text-align: left;
    text-decoration: none solid rgb(255, 255, 255);
    text-emphasis-color: rgb(255, 255, 255);
    text-size-adjust: 100%;
    border: 0px none rgb(255, 255, 255);
    font: 15px "Roboto Condensed", sans-serif;
    outline: rgb(255, 255, 255) none 0px;
}/*#DIV_17:before, #DIV_32:before, #DIV_43:before, #DIV_54:before*/

#SPAN_18, #SPAN_33, #SPAN_44, #SPAN_55 {
    border-block-end-color: rgb(255, 255, 255);
    border-block-start-color: rgb(255, 255, 255);
    border-inline-end-color: rgb(255, 255, 255);
    border-inline-start-color: rgb(255, 255, 255);
    box-sizing: border-box;
    caret-color: rgb(255, 255, 255);
    color: rgb(255, 255, 255);
    column-rule-color: rgb(255, 255, 255);
    letter-spacing: 1px;
    max-inline-size: 100%;
    max-width: 100%;
    overflow-wrap: break-word;
    text-align: left;
    text-decoration: none solid rgb(255, 255, 255);
    text-emphasis-color: rgb(255, 255, 255);
    text-size-adjust: 100%;
    border: 0px none rgb(255, 255, 255);
    flex: 1 1 auto;
    font: 15px "Roboto Condensed", sans-serif;
    outline: rgb(255, 255, 255) none 0px;
}/*#SPAN_18, #SPAN_33, #SPAN_44, #SPAN_55*/

#SPAN_18:after, #SPAN_33:after, #SPAN_44:after, #SPAN_55:after {
    border-block-end-color: rgb(255, 255, 255);
    border-block-start-color: rgb(255, 255, 255);
    border-inline-end-color: rgb(255, 255, 255);
    border-inline-start-color: rgb(255, 255, 255);
    caret-color: rgb(255, 255, 255);
    color: rgb(255, 255, 255);
    column-rule-color: rgb(255, 255, 255);
    letter-spacing: 1px;
    overflow-wrap: break-word;
    text-align: left;
    text-decoration: none solid rgb(255, 255, 255);
    text-emphasis-color: rgb(255, 255, 255);
    text-size-adjust: 100%;
    border: 0px none rgb(255, 255, 255);
    font: 15px "Roboto Condensed", sans-serif;
    outline: rgb(255, 255, 255) none 0px;
}/*#SPAN_18:after, #SPAN_33:after, #SPAN_44:after, #SPAN_55:after*/

#SPAN_18:before, #SPAN_33:before, #SPAN_44:before, #SPAN_55:before {
    border-block-end-color: rgb(255, 255, 255);
    border-block-start-color: rgb(255, 255, 255);
    border-inline-end-color: rgb(255, 255, 255);
    border-inline-start-color: rgb(255, 255, 255);
    caret-color: rgb(255, 255, 255);
    color: rgb(255, 255, 255);
    column-rule-color: rgb(255, 255, 255);
    letter-spacing: 1px;
    overflow-wrap: break-word;
    text-align: left;
    text-decoration: none solid rgb(255, 255, 255);
    text-emphasis-color: rgb(255, 255, 255);
    text-size-adjust: 100%;
    border: 0px none rgb(255, 255, 255);
    font: 15px "Roboto Condensed", sans-serif;
    outline: rgb(255, 255, 255) none 0px;
}/*#SPAN_18:before, #SPAN_33:before, #SPAN_44:before, #SPAN_55:before*/

#DIV_19, #DIV_34, #DIV_45, #DIV_56 {
    border-block-end-color: rgb(51, 51, 51);
    border-block-start-color: rgb(51, 51, 51);
    border-inline-end-color: rgb(51, 51, 51);
    border-inline-start-color: rgb(51, 51, 51);
    box-sizing: border-box;
    caret-color: rgb(51, 51, 51);
    color: rgb(51, 51, 51);
    column-rule-color: rgb(51, 51, 51);
    display: none;
    overflow-wrap: break-word;
    text-align: center;
    text-decoration: none solid rgb(51, 51, 51);
    text-emphasis-color: rgb(51, 51, 51);
    text-size-adjust: 100%;
    border: 0px none rgb(51, 51, 51);
    font: 14px Montserrat, sans-serif;
    outline: rgb(51, 51, 51) none 0px;
}/*#DIV_19, #DIV_34, #DIV_45, #DIV_56*/

#DIV_19:after, #DIV_34:after, #DIV_45:after, #DIV_56:after {
    border-block-end-color: rgb(51, 51, 51);
    border-block-start-color: rgb(51, 51, 51);
    border-inline-end-color: rgb(51, 51, 51);
    border-inline-start-color: rgb(51, 51, 51);
    caret-color: rgb(51, 51, 51);
    color: rgb(51, 51, 51);
    column-rule-color: rgb(51, 51, 51);
    overflow-wrap: break-word;
    text-align: center;
    text-decoration: none solid rgb(51, 51, 51);
    text-emphasis-color: rgb(51, 51, 51);
    text-size-adjust: 100%;
    border: 0px none rgb(51, 51, 51);
    font: 14px Montserrat, sans-serif;
    outline: rgb(51, 51, 51) none 0px;
}/*#DIV_19:after, #DIV_34:after, #DIV_45:after, #DIV_56:after*/

#DIV_19:before, #DIV_34:before, #DIV_45:before, #DIV_56:before {
    border-block-end-color: rgb(51, 51, 51);
    border-block-start-color: rgb(51, 51, 51);
    border-inline-end-color: rgb(51, 51, 51);
    border-inline-start-color: rgb(51, 51, 51);
    caret-color: rgb(51, 51, 51);
    color: rgb(51, 51, 51);
    column-rule-color: rgb(51, 51, 51);
    overflow-wrap: break-word;
    text-align: center;
    text-decoration: none solid rgb(51, 51, 51);
    text-emphasis-color: rgb(51, 51, 51);
    text-size-adjust: 100%;
    border: 0px none rgb(51, 51, 51);
    font: 14px Montserrat, sans-serif;
    outline: rgb(51, 51, 51) none 0px;
}/*#DIV_19:before, #DIV_34:before, #DIV_45:before, #DIV_56:before*/

#SPAN_20, #SPAN_35, #SPAN_46, #SPAN_57 {
    border-block-end-color: rgb(51, 51, 51);
    border-block-start-color: rgb(51, 51, 51);
    border-inline-end-color: rgb(51, 51, 51);
    border-inline-start-color: rgb(51, 51, 51);
    box-sizing: border-box;
    caret-color: rgb(51, 51, 51);
    color: rgb(51, 51, 51);
    column-rule-color: rgb(51, 51, 51);
    max-inline-size: 100%;
    max-width: 100%;
    overflow-wrap: break-word;
    text-align: center;
    text-decoration: none solid rgb(51, 51, 51);
    text-emphasis-color: rgb(51, 51, 51);
    text-size-adjust: 100%;
    border: 0px none rgb(51, 51, 51);
    flex: 1 1 auto;
    font: 14px Montserrat, sans-serif;
    outline: rgb(51, 51, 51) none 0px;
}/*#SPAN_20, #SPAN_35, #SPAN_46, #SPAN_57*/

#SPAN_20:after, #SPAN_35:after, #SPAN_46:after, #SPAN_57:after {
    border-block-end-color: rgb(51, 51, 51);
    border-block-start-color: rgb(51, 51, 51);
    border-inline-end-color: rgb(51, 51, 51);
    border-inline-start-color: rgb(51, 51, 51);
    caret-color: rgb(51, 51, 51);
    color: rgb(51, 51, 51);
    column-rule-color: rgb(51, 51, 51);
    overflow-wrap: break-word;
    text-align: center;
    text-decoration: none solid rgb(51, 51, 51);
    text-emphasis-color: rgb(51, 51, 51);
    text-size-adjust: 100%;
    border: 0px none rgb(51, 51, 51);
    font: 14px Montserrat, sans-serif;
    outline: rgb(51, 51, 51) none 0px;
}/*#SPAN_20:after, #SPAN_35:after, #SPAN_46:after, #SPAN_57:after*/

#SPAN_20:before, #SPAN_35:before, #SPAN_46:before, #SPAN_57:before {
    border-block-end-color: rgb(51, 51, 51);
    border-block-start-color: rgb(51, 51, 51);
    border-inline-end-color: rgb(51, 51, 51);
    border-inline-start-color: rgb(51, 51, 51);
    caret-color: rgb(51, 51, 51);
    color: rgb(51, 51, 51);
    column-rule-color: rgb(51, 51, 51);
    overflow-wrap: break-word;
    text-align: center;
    text-decoration: none solid rgb(51, 51, 51);
    text-emphasis-color: rgb(51, 51, 51);
    text-size-adjust: 100%;
    border: 0px none rgb(51, 51, 51);
    font: 14px Montserrat, sans-serif;
    outline: rgb(51, 51, 51) none 0px;
}/*#SPAN_20:before, #SPAN_35:before, #SPAN_46:before, #SPAN_57:before*/

#path_26 {
    border-block-end-color: rgb(0, 0, 238);
    border-block-start-color: rgb(0, 0, 238);
    border-inline-end-color: rgb(0, 0, 238);
    border-inline-start-color: rgb(0, 0, 238);
    box-sizing: border-box;
    caret-color: rgb(0, 0, 238);
    color: rgb(0, 0, 238);
    column-rule-color: rgb(0, 0, 238);
    cursor: pointer;
    d: path("M 0 0 H 64 V 64 H 0 Z");
    fill: url("#paint0_linear_217_1057");
    overflow-wrap: break-word;
    perspective-origin: 0px 0px;
    text-align: center;
    text-decoration: none solid rgb(0, 0, 238);
    text-emphasis-color: rgb(0, 0, 238);
    text-size-adjust: 100%;
    transform-origin: 0px 0px;
    border: 0px none rgb(0, 0, 238);
    font: 14px Montserrat, sans-serif;
    outline: rgb(0, 0, 238) none 0px;
}/*#path_26*/

#path_26:after {
    border-block-end-color: rgb(0, 0, 238);
    border-block-start-color: rgb(0, 0, 238);
    border-inline-end-color: rgb(0, 0, 238);
    border-inline-start-color: rgb(0, 0, 238);
    caret-color: rgb(0, 0, 238);
    color: rgb(0, 0, 238);
    column-rule-color: rgb(0, 0, 238);
    cursor: pointer;
    fill: url("#paint0_linear_217_1057");
    overflow-wrap: break-word;
    text-align: center;
    text-decoration: none solid rgb(0, 0, 238);
    text-emphasis-color: rgb(0, 0, 238);
    text-size-adjust: 100%;
    border: 0px none rgb(0, 0, 238);
    font: 14px Montserrat, sans-serif;
    outline: rgb(0, 0, 238) none 0px;
}/*#path_26:after*/

#path_26:before {
    border-block-end-color: rgb(0, 0, 238);
    border-block-start-color: rgb(0, 0, 238);
    border-inline-end-color: rgb(0, 0, 238);
    border-inline-start-color: rgb(0, 0, 238);
    caret-color: rgb(0, 0, 238);
    color: rgb(0, 0, 238);
    column-rule-color: rgb(0, 0, 238);
    cursor: pointer;
    fill: url("#paint0_linear_217_1057");
    overflow-wrap: break-word;
    text-align: center;
    text-decoration: none solid rgb(0, 0, 238);
    text-emphasis-color: rgb(0, 0, 238);
    text-size-adjust: 100%;
    border: 0px none rgb(0, 0, 238);
    font: 14px Montserrat, sans-serif;
    outline: rgb(0, 0, 238) none 0px;
}/*#path_26:before*/

#path_27 {
    border-block-end-color: rgb(0, 0, 238);
    border-block-start-color: rgb(0, 0, 238);
    border-inline-end-color: rgb(0, 0, 238);
    border-inline-start-color: rgb(0, 0, 238);
    box-sizing: border-box;
    caret-color: rgb(0, 0, 238);
    clip-rule: evenodd;
    color: rgb(0, 0, 238);
    column-rule-color: rgb(0, 0, 238);
    cursor: pointer;
    d: path("M 41.448 22.542 A 13.223 13.223 0 0 0 32.056 18.667 C 24.737 18.667 18.781 24.594 18.778 31.88 C 18.778 34.209 19.388 36.482 20.55 38.486 L 18.667 45.333 L 25.705 43.496 A 13.318 13.318 0 0 0 32.05 45.104 H 32.056 C 39.374 45.104 45.33 39.176 45.333 31.89 A 13.103 13.103 0 0 0 41.448 22.542 Z M 32.056 42.872 H 32.051 C 30.071 42.872 28.129 42.342 26.435 41.342 L 26.032 41.104 L 21.855 42.194 L 22.969 38.141 L 22.707 37.725 A 10.918 10.918 0 0 1 21.02 31.88 C 21.022 25.825 25.973 20.898 32.06 20.898 A 10.987 10.987 0 0 1 39.862 24.119 A 10.89 10.89 0 0 1 43.092 31.889 C 43.089 37.946 38.138 42.873 32.056 42.873 Z M 38.109 34.647 C 37.777 34.482 36.146 33.683 35.842 33.573 C 35.538 33.463 35.317 33.407 35.096 33.738 C 34.875 34.068 34.239 34.812 34.046 35.033 C 33.852 35.253 33.658 35.281 33.326 35.115 C 32.995 34.95 31.926 34.601 30.658 33.476 C 29.672 32.601 29.006 31.52 28.813 31.189 C 28.619 30.859 28.793 30.679 28.959 30.515 C 29.108 30.367 29.29 30.129 29.456 29.937 C 29.622 29.744 29.677 29.607 29.788 29.386 C 29.898 29.166 29.843 28.973 29.76 28.808 C 29.677 28.642 29.014 27.017 28.737 26.356 C 28.468 25.712 28.195 25.799 27.991 25.789 C 27.798 25.779 27.576 25.777 27.355 25.777 C 27.134 25.777 26.775 25.86 26.47 26.19 C 26.166 26.521 25.31 27.32 25.31 28.945 S 26.498 32.14 26.664 32.361 C 26.83 32.581 29.004 35.916 32.331 37.346 C 33.123 37.686 33.741 37.889 34.223 38.042 C 35.017 38.293 35.741 38.257 36.313 38.172 C 36.95 38.078 38.275 37.374 38.551 36.602 C 38.828 35.832 38.828 35.17 38.745 35.032 C 38.662 34.895 38.441 34.812 38.109 34.647 Z");
    fill: rgb(255, 255, 255);
    fill-rule: evenodd;
    overflow-wrap: break-word;
    perspective-origin: 0px 0px;
    text-align: center;
    text-decoration: none solid rgb(0, 0, 238);
    text-emphasis-color: rgb(0, 0, 238);
    text-size-adjust: 100%;
    transform-origin: 0px 0px;
    border: 0px none rgb(0, 0, 238);
    font: 14px Montserrat, sans-serif;
    outline: rgb(0, 0, 238) none 0px;
}/*#path_27*/

#path_27:after {
    border-block-end-color: rgb(0, 0, 238);
    border-block-start-color: rgb(0, 0, 238);
    border-inline-end-color: rgb(0, 0, 238);
    border-inline-start-color: rgb(0, 0, 238);
    caret-color: rgb(0, 0, 238);
    clip-rule: evenodd;
    color: rgb(0, 0, 238);
    column-rule-color: rgb(0, 0, 238);
    cursor: pointer;
    fill: rgb(255, 255, 255);
    fill-rule: evenodd;
    overflow-wrap: break-word;
    text-align: center;
    text-decoration: none solid rgb(0, 0, 238);
    text-emphasis-color: rgb(0, 0, 238);
    text-size-adjust: 100%;
    border: 0px none rgb(0, 0, 238);
    font: 14px Montserrat, sans-serif;
    outline: rgb(0, 0, 238) none 0px;
}/*#path_27:after*/

#path_27:before {
    border-block-end-color: rgb(0, 0, 238);
    border-block-start-color: rgb(0, 0, 238);
    border-inline-end-color: rgb(0, 0, 238);
    border-inline-start-color: rgb(0, 0, 238);
    caret-color: rgb(0, 0, 238);
    clip-rule: evenodd;
    color: rgb(0, 0, 238);
    column-rule-color: rgb(0, 0, 238);
    cursor: pointer;
    fill: rgb(255, 255, 255);
    fill-rule: evenodd;
    overflow-wrap: break-word;
    text-align: center;
    text-decoration: none solid rgb(0, 0, 238);
    text-emphasis-color: rgb(0, 0, 238);
    text-size-adjust: 100%;
    border: 0px none rgb(0, 0, 238);
    font: 14px Montserrat, sans-serif;
    outline: rgb(0, 0, 238) none 0px;
}/*#path_27:before*/

#linearGradient_29 {
    border-block-end-color: rgb(0, 0, 238);
    border-block-start-color: rgb(0, 0, 238);
    border-inline-end-color: rgb(0, 0, 238);
    border-inline-start-color: rgb(0, 0, 238);
    box-sizing: border-box;
    caret-color: rgb(0, 0, 238);
    color: rgb(0, 0, 238);
    column-rule-color: rgb(0, 0, 238);
    cursor: pointer;
    fill: none;
    overflow-wrap: break-word;
    perspective-origin: 0px 0px;
    text-align: center;
    text-decoration: none solid rgb(0, 0, 238);
    text-emphasis-color: rgb(0, 0, 238);
    text-size-adjust: 100%;
    transform-origin: 0px 0px;
    border: 0px none rgb(0, 0, 238);
    font: 14px Montserrat, sans-serif;
    outline: rgb(0, 0, 238) none 0px;
}/*#linearGradient_29*/

#linearGradient_29:after {
    border-block-end-color: rgb(0, 0, 238);
    border-block-start-color: rgb(0, 0, 238);
    border-inline-end-color: rgb(0, 0, 238);
    border-inline-start-color: rgb(0, 0, 238);
    caret-color: rgb(0, 0, 238);
    color: rgb(0, 0, 238);
    column-rule-color: rgb(0, 0, 238);
    cursor: pointer;
    fill: none;
    overflow-wrap: break-word;
    text-align: center;
    text-decoration: none solid rgb(0, 0, 238);
    text-emphasis-color: rgb(0, 0, 238);
    text-size-adjust: 100%;
    border: 0px none rgb(0, 0, 238);
    font: 14px Montserrat, sans-serif;
    outline: rgb(0, 0, 238) none 0px;
}/*#linearGradient_29:after*/

#linearGradient_29:before {
    border-block-end-color: rgb(0, 0, 238);
    border-block-start-color: rgb(0, 0, 238);
    border-inline-end-color: rgb(0, 0, 238);
    border-inline-start-color: rgb(0, 0, 238);
    caret-color: rgb(0, 0, 238);
    color: rgb(0, 0, 238);
    column-rule-color: rgb(0, 0, 238);
    cursor: pointer;
    fill: none;
    overflow-wrap: break-word;
    text-align: center;
    text-decoration: none solid rgb(0, 0, 238);
    text-emphasis-color: rgb(0, 0, 238);
    text-size-adjust: 100%;
    border: 0px none rgb(0, 0, 238);
    font: 14px Montserrat, sans-serif;
    outline: rgb(0, 0, 238) none 0px;
}/*#linearGradient_29:before*/

#stop_30 {
    border-block-end-color: rgb(0, 0, 238);
    border-block-start-color: rgb(0, 0, 238);
    border-inline-end-color: rgb(0, 0, 238);
    border-inline-start-color: rgb(0, 0, 238);
    box-sizing: border-box;
    caret-color: rgb(0, 0, 238);
    color: rgb(0, 0, 238);
    column-rule-color: rgb(0, 0, 238);
    cursor: pointer;
    fill: none;
    overflow-wrap: break-word;
    stop-color: rgb(94, 251, 122);
    text-align: center;
    text-decoration: none solid rgb(0, 0, 238);
    text-emphasis-color: rgb(0, 0, 238);
    text-size-adjust: 100%;
    transform-origin: 0px 0px;
    border: 0px none rgb(0, 0, 238);
    font: 14px Montserrat, sans-serif;
    outline: rgb(0, 0, 238) none 0px;
}/*#stop_30*/

#stop_30:after {
    border-block-end-color: rgb(0, 0, 238);
    border-block-start-color: rgb(0, 0, 238);
    border-inline-end-color: rgb(0, 0, 238);
    border-inline-start-color: rgb(0, 0, 238);
    caret-color: rgb(0, 0, 238);
    color: rgb(0, 0, 238);
    column-rule-color: rgb(0, 0, 238);
    cursor: pointer;
    fill: none;
    overflow-wrap: break-word;
    text-align: center;
    text-decoration: none solid rgb(0, 0, 238);
    text-emphasis-color: rgb(0, 0, 238);
    text-size-adjust: 100%;
    border: 0px none rgb(0, 0, 238);
    font: 14px Montserrat, sans-serif;
    outline: rgb(0, 0, 238) none 0px;
}/*#stop_30:after*/

#stop_30:before {
    border-block-end-color: rgb(0, 0, 238);
    border-block-start-color: rgb(0, 0, 238);
    border-inline-end-color: rgb(0, 0, 238);
    border-inline-start-color: rgb(0, 0, 238);
    caret-color: rgb(0, 0, 238);
    color: rgb(0, 0, 238);
    column-rule-color: rgb(0, 0, 238);
    cursor: pointer;
    fill: none;
    overflow-wrap: break-word;
    text-align: center;
    text-decoration: none solid rgb(0, 0, 238);
    text-emphasis-color: rgb(0, 0, 238);
    text-size-adjust: 100%;
    border: 0px none rgb(0, 0, 238);
    font: 14px Montserrat, sans-serif;
    outline: rgb(0, 0, 238) none 0px;
}/*#stop_30:before*/

#stop_31 {
    border-block-end-color: rgb(0, 0, 238);
    border-block-start-color: rgb(0, 0, 238);
    border-inline-end-color: rgb(0, 0, 238);
    border-inline-start-color: rgb(0, 0, 238);
    box-sizing: border-box;
    caret-color: rgb(0, 0, 238);
    color: rgb(0, 0, 238);
    column-rule-color: rgb(0, 0, 238);
    cursor: pointer;
    fill: none;
    overflow-wrap: break-word;
    stop-color: rgb(38, 208, 68);
    text-align: center;
    text-decoration: none solid rgb(0, 0, 238);
    text-emphasis-color: rgb(0, 0, 238);
    text-size-adjust: 100%;
    transform-origin: 0px 0px;
    border: 0px none rgb(0, 0, 238);
    font: 14px Montserrat, sans-serif;
    outline: rgb(0, 0, 238) none 0px;
}/*#stop_31*/

#stop_31:after {
    border-block-end-color: rgb(0, 0, 238);
    border-block-start-color: rgb(0, 0, 238);
    border-inline-end-color: rgb(0, 0, 238);
    border-inline-start-color: rgb(0, 0, 238);
    caret-color: rgb(0, 0, 238);
    color: rgb(0, 0, 238);
    column-rule-color: rgb(0, 0, 238);
    cursor: pointer;
    fill: none;
    overflow-wrap: break-word;
    text-align: center;
    text-decoration: none solid rgb(0, 0, 238);
    text-emphasis-color: rgb(0, 0, 238);
    text-size-adjust: 100%;
    border: 0px none rgb(0, 0, 238);
    font: 14px Montserrat, sans-serif;
    outline: rgb(0, 0, 238) none 0px;
}/*#stop_31:after*/

#stop_31:before {
    border-block-end-color: rgb(0, 0, 238);
    border-block-start-color: rgb(0, 0, 238);
    border-inline-end-color: rgb(0, 0, 238);
    border-inline-start-color: rgb(0, 0, 238);
    caret-color: rgb(0, 0, 238);
    color: rgb(0, 0, 238);
    column-rule-color: rgb(0, 0, 238);
    cursor: pointer;
    fill: none;
    overflow-wrap: break-word;
    text-align: center;
    text-decoration: none solid rgb(0, 0, 238);
    text-emphasis-color: rgb(0, 0, 238);
    text-size-adjust: 100%;
    border: 0px none rgb(0, 0, 238);
    font: 14px Montserrat, sans-serif;
    outline: rgb(0, 0, 238) none 0px;
}/*#stop_31:before*/

#path_41 {
    border-block-end-color: rgb(0, 0, 238);
    border-block-start-color: rgb(0, 0, 238);
    border-inline-end-color: rgb(0, 0, 238);
    border-inline-start-color: rgb(0, 0, 238);
    box-sizing: border-box;
    caret-color: rgb(0, 0, 238);
    color: rgb(0, 0, 238);
    column-rule-color: rgb(0, 0, 238);
    cursor: pointer;
    d: path("M 0 0 H 64 V 64 H 0 Z");
    fill: rgb(123, 81, 157);
    overflow-wrap: break-word;
    perspective-origin: 0px 0px;
    text-align: center;
    text-decoration: none solid rgb(0, 0, 238);
    text-emphasis-color: rgb(0, 0, 238);
    text-size-adjust: 100%;
    transform-origin: 0px 0px;
    border: 0px none rgb(0, 0, 238);
    font: 14px Montserrat, sans-serif;
    outline: rgb(0, 0, 238) none 0px;
}/*#path_41*/

#path_41:after {
    border-block-end-color: rgb(0, 0, 238);
    border-block-start-color: rgb(0, 0, 238);
    border-inline-end-color: rgb(0, 0, 238);
    border-inline-start-color: rgb(0, 0, 238);
    caret-color: rgb(0, 0, 238);
    color: rgb(0, 0, 238);
    column-rule-color: rgb(0, 0, 238);
    cursor: pointer;
    fill: rgb(123, 81, 157);
    overflow-wrap: break-word;
    text-align: center;
    text-decoration: none solid rgb(0, 0, 238);
    text-emphasis-color: rgb(0, 0, 238);
    text-size-adjust: 100%;
    border: 0px none rgb(0, 0, 238);
    font: 14px Montserrat, sans-serif;
    outline: rgb(0, 0, 238) none 0px;
}/*#path_41:after*/

#path_41:before {
    border-block-end-color: rgb(0, 0, 238);
    border-block-start-color: rgb(0, 0, 238);
    border-inline-end-color: rgb(0, 0, 238);
    border-inline-start-color: rgb(0, 0, 238);
    caret-color: rgb(0, 0, 238);
    color: rgb(0, 0, 238);
    column-rule-color: rgb(0, 0, 238);
    cursor: pointer;
    fill: rgb(123, 81, 157);
    overflow-wrap: break-word;
    text-align: center;
    text-decoration: none solid rgb(0, 0, 238);
    text-emphasis-color: rgb(0, 0, 238);
    text-size-adjust: 100%;
    border: 0px none rgb(0, 0, 238);
    font: 14px Montserrat, sans-serif;
    outline: rgb(0, 0, 238) none 0px;
}/*#path_41:before*/

#path_42 {
    border-block-end-color: rgb(0, 0, 238);
    border-block-start-color: rgb(0, 0, 238);
    border-inline-end-color: rgb(0, 0, 238);
    border-inline-start-color: rgb(0, 0, 238);
    box-sizing: border-box;
    caret-color: rgb(0, 0, 238);
    clip-rule: evenodd;
    color: rgb(0, 0, 238);
    column-rule-color: rgb(0, 0, 238);
    cursor: pointer;
    d: path("M 42.53 37.566 C 41.89 37.061 41.213 36.598 40.538 36.136 C 40.396 36.04 40.255 35.943 40.115 35.846 C 38.49 34.728 37.005 34.641 35.793 36.441 C 35.113 37.451 34.16 37.495 33.163 37.051 C 30.417 35.831 28.297 33.95 27.054 31.213 C 26.504 30.003 26.512 28.917 27.798 28.061 C 28.478 27.608 29.164 27.072 29.11 26.083 C 29.038 24.793 25.843 20.481 24.58 20.026 C 24.059 19.837 23.539 19.849 23.007 20.024 C 20.048 21 18.823 23.386 19.997 26.21 C 23.498 34.638 29.66 40.505 38.142 44.086 C 38.495 44.234 38.876 44.318 39.162 44.381 C 39.269 44.404 39.362 44.425 39.435 44.444 C 41.367 44.464 43.629 42.639 44.282 40.829 C 44.835 39.299 43.875 38.579 42.92 37.864 C 42.789 37.765 42.658 37.667 42.53 37.566 Z M 33.02 20.89 C 39.22 21.825 42.08 24.716 42.87 30.84 C 42.886 30.963 42.888 31.1 42.89 31.238 C 42.899 31.736 42.908 32.26 43.552 32.272 C 44.271 32.285 44.244 31.702 44.22 31.179 C 44.215 31.077 44.21 30.976 44.212 30.883 C 44.284 25.116 39.155 19.77 33.199 19.573 C 33.093 19.588 32.961 19.579 32.819 19.57 C 32.36 19.539 31.806 19.501 31.761 20.257 C 31.731 20.791 32.228 20.826 32.691 20.859 C 32.805 20.867 32.918 20.875 33.019 20.891 Z M 33.674 23.719 C 33.212 23.643 32.709 23.559 32.608 24.162 C 32.507 24.765 32.981 24.83 33.422 24.891 C 33.525 24.905 33.627 24.919 33.719 24.939 C 37.001 25.673 38.145 26.868 38.685 30.124 C 38.701 30.218 38.707 30.324 38.714 30.429 C 38.74 30.859 38.767 31.313 39.414 31.215 C 39.866 31.146 39.853 30.768 39.841 30.427 C 39.837 30.317 39.834 30.211 39.846 30.121 C 39.872 26.994 37.194 24.149 33.914 23.755 A 5.588 5.588 0 0 1 33.674 23.719 Z M 33.414 26.722 C 33.539 26.357 33.874 26.32 34.215 26.312 C 35.67 26.275 37.375 28.019 37.351 29.528 C 37.366 29.94 37.322 30.375 36.834 30.433 C 36.482 30.475 36.251 30.179 36.214 29.815 C 36.08 28.472 35.371 27.678 34.024 27.461 C 33.621 27.397 33.224 27.267 33.414 26.721 Z");
    fill: rgb(255, 255, 255);
    fill-rule: evenodd;
    overflow-wrap: break-word;
    perspective-origin: 0px 0px;
    text-align: center;
    text-decoration: none solid rgb(0, 0, 238);
    text-emphasis-color: rgb(0, 0, 238);
    text-size-adjust: 100%;
    transform-origin: 0px 0px;
    border: 0px none rgb(0, 0, 238);
    font: 14px Montserrat, sans-serif;
    outline: rgb(0, 0, 238) none 0px;
}/*#path_42*/

#path_42:after {
    border-block-end-color: rgb(0, 0, 238);
    border-block-start-color: rgb(0, 0, 238);
    border-inline-end-color: rgb(0, 0, 238);
    border-inline-start-color: rgb(0, 0, 238);
    caret-color: rgb(0, 0, 238);
    clip-rule: evenodd;
    color: rgb(0, 0, 238);
    column-rule-color: rgb(0, 0, 238);
    cursor: pointer;
    fill: rgb(255, 255, 255);
    fill-rule: evenodd;
    overflow-wrap: break-word;
    text-align: center;
    text-decoration: none solid rgb(0, 0, 238);
    text-emphasis-color: rgb(0, 0, 238);
    text-size-adjust: 100%;
    border: 0px none rgb(0, 0, 238);
    font: 14px Montserrat, sans-serif;
    outline: rgb(0, 0, 238) none 0px;
}/*#path_42:after*/

#path_42:before {
    border-block-end-color: rgb(0, 0, 238);
    border-block-start-color: rgb(0, 0, 238);
    border-inline-end-color: rgb(0, 0, 238);
    border-inline-start-color: rgb(0, 0, 238);
    caret-color: rgb(0, 0, 238);
    clip-rule: evenodd;
    color: rgb(0, 0, 238);
    column-rule-color: rgb(0, 0, 238);
    cursor: pointer;
    fill: rgb(255, 255, 255);
    fill-rule: evenodd;
    overflow-wrap: break-word;
    text-align: center;
    text-decoration: none solid rgb(0, 0, 238);
    text-emphasis-color: rgb(0, 0, 238);
    text-size-adjust: 100%;
    border: 0px none rgb(0, 0, 238);
    font: 14px Montserrat, sans-serif;
    outline: rgb(0, 0, 238) none 0px;
}/*#path_42:before*/

#path_52 {
    border-block-end-color: rgb(0, 0, 238);
    border-block-start-color: rgb(0, 0, 238);
    border-inline-end-color: rgb(0, 0, 238);
    border-inline-start-color: rgb(0, 0, 238);
    box-sizing: border-box;
    caret-color: rgb(0, 0, 238);
    color: rgb(0, 0, 238);
    column-rule-color: rgb(0, 0, 238);
    cursor: pointer;
    d: path("M 0 0 H 64 V 64 H 0 Z");
    fill: rgb(39, 135, 245);
    overflow-wrap: break-word;
    perspective-origin: 0px 0px;
    text-align: center;
    text-decoration: none solid rgb(0, 0, 238);
    text-emphasis-color: rgb(0, 0, 238);
    text-size-adjust: 100%;
    transform-origin: 0px 0px;
    border: 0px none rgb(0, 0, 238);
    font: 14px Montserrat, sans-serif;
    outline: rgb(0, 0, 238) none 0px;
}/*#path_52*/

#path_52:after {
    border-block-end-color: rgb(0, 0, 238);
    border-block-start-color: rgb(0, 0, 238);
    border-inline-end-color: rgb(0, 0, 238);
    border-inline-start-color: rgb(0, 0, 238);
    caret-color: rgb(0, 0, 238);
    color: rgb(0, 0, 238);
    column-rule-color: rgb(0, 0, 238);
    cursor: pointer;
    fill: rgb(39, 135, 245);
    overflow-wrap: break-word;
    text-align: center;
    text-decoration: none solid rgb(0, 0, 238);
    text-emphasis-color: rgb(0, 0, 238);
    text-size-adjust: 100%;
    border: 0px none rgb(0, 0, 238);
    font: 14px Montserrat, sans-serif;
    outline: rgb(0, 0, 238) none 0px;
}/*#path_52:after*/

#path_52:before {
    border-block-end-color: rgb(0, 0, 238);
    border-block-start-color: rgb(0, 0, 238);
    border-inline-end-color: rgb(0, 0, 238);
    border-inline-start-color: rgb(0, 0, 238);
    caret-color: rgb(0, 0, 238);
    color: rgb(0, 0, 238);
    column-rule-color: rgb(0, 0, 238);
    cursor: pointer;
    fill: rgb(39, 135, 245);
    overflow-wrap: break-word;
    text-align: center;
    text-decoration: none solid rgb(0, 0, 238);
    text-emphasis-color: rgb(0, 0, 238);
    text-size-adjust: 100%;
    border: 0px none rgb(0, 0, 238);
    font: 14px Montserrat, sans-serif;
    outline: rgb(0, 0, 238) none 0px;
}/*#path_52:before*/

#path_53 {
    border-block-end-color: rgb(0, 0, 238);
    border-block-start-color: rgb(0, 0, 238);
    border-inline-end-color: rgb(0, 0, 238);
    border-inline-start-color: rgb(0, 0, 238);
    box-sizing: border-box;
    caret-color: rgb(0, 0, 238);
    color: rgb(0, 0, 238);
    column-rule-color: rgb(0, 0, 238);
    cursor: pointer;
    d: path("M 33.28 40.871 C 23.556 40.871 18.009 34.204 17.778 23.111 H 22.648 C 22.808 31.253 26.4 34.702 29.244 35.413 V 23.111 H 33.831 V 30.133 C 36.641 29.831 39.591 26.631 40.587 23.111 H 45.173 A 13.547 13.547 0 0 1 38.933 31.964 A 14.046 14.046 0 0 1 46.24 40.871 H 41.191 A 8.783 8.783 0 0 0 33.831 34.524 V 40.871 H 33.281 Z");
    fill: rgb(255, 255, 255);
    overflow-wrap: break-word;
    perspective-origin: 0px 0px;
    text-align: center;
    text-decoration: none solid rgb(0, 0, 238);
    text-emphasis-color: rgb(0, 0, 238);
    text-size-adjust: 100%;
    transform-origin: 0px 0px;
    border: 0px none rgb(0, 0, 238);
    font: 14px Montserrat, sans-serif;
    outline: rgb(0, 0, 238) none 0px;
}/*#path_53*/

#path_53:after {
    border-block-end-color: rgb(0, 0, 238);
    border-block-start-color: rgb(0, 0, 238);
    border-inline-end-color: rgb(0, 0, 238);
    border-inline-start-color: rgb(0, 0, 238);
    caret-color: rgb(0, 0, 238);
    color: rgb(0, 0, 238);
    column-rule-color: rgb(0, 0, 238);
    cursor: pointer;
    fill: rgb(255, 255, 255);
    overflow-wrap: break-word;
    text-align: center;
    text-decoration: none solid rgb(0, 0, 238);
    text-emphasis-color: rgb(0, 0, 238);
    text-size-adjust: 100%;
    border: 0px none rgb(0, 0, 238);
    font: 14px Montserrat, sans-serif;
    outline: rgb(0, 0, 238) none 0px;
}/*#path_53:after*/

#path_53:before {
    border-block-end-color: rgb(0, 0, 238);
    border-block-start-color: rgb(0, 0, 238);
    border-inline-end-color: rgb(0, 0, 238);
    border-inline-start-color: rgb(0, 0, 238);
    caret-color: rgb(0, 0, 238);
    color: rgb(0, 0, 238);
    column-rule-color: rgb(0, 0, 238);
    cursor: pointer;
    fill: rgb(255, 255, 255);
    overflow-wrap: break-word;
    text-align: center;
    text-decoration: none solid rgb(0, 0, 238);
    text-emphasis-color: rgb(0, 0, 238);
    text-size-adjust: 100%;
    border: 0px none rgb(0, 0, 238);
    font: 14px Montserrat, sans-serif;
    outline: rgb(0, 0, 238) none 0px;
}/*#path_53:before*/

#DIV_58 {
    align-items: flex-start;
    border-block-end-color: rgb(51, 51, 51);
    border-block-start-color: rgb(51, 51, 51);
    border-inline-end-color: rgb(51, 51, 51);
    border-inline-start-color: rgb(51, 51, 51);
    bottom: 0px;
    box-sizing: border-box;
    caret-color: rgb(51, 51, 51);
    color: rgb(51, 51, 51);
    column-rule-color: rgb(51, 51, 51);
    display: none;
    inset-block-end: 0px;
    inset-block-start: 0px;
    inset-inline-end: 0px;
    inset-inline-start: 0px;
    left: 0px;
    overflow-wrap: break-word;
    pointer-events: none;
    position: absolute;
    right: 0px;
    text-align: center;
    text-decoration: none solid rgb(51, 51, 51);
    text-emphasis-color: rgb(51, 51, 51);
    text-size-adjust: 100%;
    top: 0px;
    z-index: 2;
    border: 0px none rgb(51, 51, 51);
    font: 14px Montserrat, sans-serif;
    outline: rgb(51, 51, 51) none 0px;
}/*#DIV_58*/

#DIV_58:after {
    border-block-end-color: rgb(51, 51, 51);
    border-block-start-color: rgb(51, 51, 51);
    border-inline-end-color: rgb(51, 51, 51);
    border-inline-start-color: rgb(51, 51, 51);
    caret-color: rgb(51, 51, 51);
    color: rgb(51, 51, 51);
    column-rule-color: rgb(51, 51, 51);
    overflow-wrap: break-word;
    pointer-events: none;
    text-align: center;
    text-decoration: none solid rgb(51, 51, 51);
    text-emphasis-color: rgb(51, 51, 51);
    text-size-adjust: 100%;
    border: 0px none rgb(51, 51, 51);
    font: 14px Montserrat, sans-serif;
    outline: rgb(51, 51, 51) none 0px;
}/*#DIV_58:after*/

#DIV_58:before {
    border-block-end-color: rgb(51, 51, 51);
    border-block-start-color: rgb(51, 51, 51);
    border-inline-end-color: rgb(51, 51, 51);
    border-inline-start-color: rgb(51, 51, 51);
    caret-color: rgb(51, 51, 51);
    color: rgb(51, 51, 51);
    column-rule-color: rgb(51, 51, 51);
    overflow-wrap: break-word;
    pointer-events: none;
    text-align: center;
    text-decoration: none solid rgb(51, 51, 51);
    text-emphasis-color: rgb(51, 51, 51);
    text-size-adjust: 100%;
    border: 0px none rgb(51, 51, 51);
    font: 14px Montserrat, sans-serif;
    outline: rgb(51, 51, 51) none 0px;
}/*#DIV_58:before*/

#DIV_59 {
    border-block-end-color: rgb(51, 51, 51);
    border-block-start-color: rgb(51, 51, 51);
    border-inline-end-color: rgb(51, 51, 51);
    border-inline-start-color: rgb(51, 51, 51);
    box-sizing: border-box;
    caret-color: rgb(51, 51, 51);
    color: rgb(51, 51, 51);
    column-rule-color: rgb(51, 51, 51);
    display: flex;
    inline-size: 100%;
    justify-content: space-between;
    margin-block-end: auto;
    margin-block-start: auto;
    overflow-wrap: break-word;
    padding-inline-end: 10px;
    padding-inline-start: 10px;
    pointer-events: none;
    text-align: center;
    text-decoration: none solid rgb(51, 51, 51);
    text-emphasis-color: rgb(51, 51, 51);
    text-size-adjust: 100%;
    width: 100%;
    border: 0px none rgb(51, 51, 51);
    flex: 0 0 auto;
    font: 14px Montserrat, sans-serif;
    margin: auto 0px;
    outline: rgb(51, 51, 51) none 0px;
    padding: 0px 10px;
}/*#DIV_59*/

#DIV_59:after {
    border-block-end-color: rgb(51, 51, 51);
    border-block-start-color: rgb(51, 51, 51);
    border-inline-end-color: rgb(51, 51, 51);
    border-inline-start-color: rgb(51, 51, 51);
    caret-color: rgb(51, 51, 51);
    color: rgb(51, 51, 51);
    column-rule-color: rgb(51, 51, 51);
    display: block;
    overflow-wrap: break-word;
    pointer-events: none;
    text-align: center;
    text-decoration: none solid rgb(51, 51, 51);
    text-emphasis-color: rgb(51, 51, 51);
    text-size-adjust: 100%;
    border: 0px none rgb(51, 51, 51);
    font: 14px Montserrat, sans-serif;
    outline: rgb(51, 51, 51) none 0px;
}/*#DIV_59:after*/

#DIV_59:before {
    border-block-end-color: rgb(51, 51, 51);
    border-block-start-color: rgb(51, 51, 51);
    border-inline-end-color: rgb(51, 51, 51);
    border-inline-start-color: rgb(51, 51, 51);
    caret-color: rgb(51, 51, 51);
    color: rgb(51, 51, 51);
    column-rule-color: rgb(51, 51, 51);
    display: block;
    overflow-wrap: break-word;
    pointer-events: none;
    text-align: center;
    text-decoration: none solid rgb(51, 51, 51);
    text-emphasis-color: rgb(51, 51, 51);
    text-size-adjust: 100%;
    border: 0px none rgb(51, 51, 51);
    font: 14px Montserrat, sans-serif;
    outline: rgb(51, 51, 51) none 0px;
}/*#DIV_59:before*/

#DIV_60 {
    block-size: 32px;
    border-block-end-color: rgb(51, 51, 51);
    border-block-start-color: rgb(51, 51, 51);
    border-inline-end-color: rgb(51, 51, 51);
    border-inline-start-color: rgb(51, 51, 51);
    box-sizing: border-box;
    caret-color: rgb(51, 51, 51);
    color: rgb(51, 51, 51);
    column-rule-color: rgb(51, 51, 51);
    cursor: pointer;
    height: 32px;
    inline-size: 20px;
    overflow-wrap: break-word;
    text-align: center;
    text-decoration: none solid rgb(51, 51, 51);
    text-emphasis-color: rgb(51, 51, 51);
    text-size-adjust: 100%;
    width: 20px;
    background: rgba(0, 0, 0, 0) url("https://massage51.ru/d/fgs16_image-placeholder.png") no-repeat scroll 0% 0% / auto padding-box border-box;
    border: 0px none rgb(51, 51, 51);
    flex: 0 0 auto;
    font: 14px Montserrat, sans-serif;
    outline: rgb(51, 51, 51) none 0px;
}/*#DIV_60*/

#DIV_60:after {
    border-block-end-color: rgb(51, 51, 51);
    border-block-start-color: rgb(51, 51, 51);
    border-inline-end-color: rgb(51, 51, 51);
    border-inline-start-color: rgb(51, 51, 51);
    caret-color: rgb(51, 51, 51);
    color: rgb(51, 51, 51);
    column-rule-color: rgb(51, 51, 51);
    cursor: pointer;
    overflow-wrap: break-word;
    text-align: center;
    text-decoration: none solid rgb(51, 51, 51);
    text-emphasis-color: rgb(51, 51, 51);
    text-size-adjust: 100%;
    border: 0px none rgb(51, 51, 51);
    font: 14px Montserrat, sans-serif;
    outline: rgb(51, 51, 51) none 0px;
}/*#DIV_60:after*/

#DIV_60:before {
    border-block-end-color: rgb(51, 51, 51);
    border-block-start-color: rgb(51, 51, 51);
    border-inline-end-color: rgb(51, 51, 51);
    border-inline-start-color: rgb(51, 51, 51);
    caret-color: rgb(51, 51, 51);
    color: rgb(51, 51, 51);
    column-rule-color: rgb(51, 51, 51);
    cursor: pointer;
    overflow-wrap: break-word;
    text-align: center;
    text-decoration: none solid rgb(51, 51, 51);
    text-emphasis-color: rgb(51, 51, 51);
    text-size-adjust: 100%;
    border: 0px none rgb(51, 51, 51);
    font: 14px Montserrat, sans-serif;
    outline: rgb(51, 51, 51) none 0px;
}/*#DIV_60:before*/

#DIV_61 {
    block-size: 32px;
    border-block-end-color: rgb(51, 51, 51);
    border-block-start-color: rgb(51, 51, 51);
    border-inline-end-color: rgb(51, 51, 51);
    border-inline-start-color: rgb(51, 51, 51);
    box-sizing: border-box;
    caret-color: rgb(51, 51, 51);
    color: rgb(51, 51, 51);
    column-rule-color: rgb(51, 51, 51);
    cursor: pointer;
    height: 32px;
    inline-size: 20px;
    overflow-wrap: break-word;
    text-align: center;
    text-decoration: none solid rgb(51, 51, 51);
    text-emphasis-color: rgb(51, 51, 51);
    text-size-adjust: 100%;
    width: 20px;
    background: rgba(0, 0, 0, 0) url("https://massage51.ru/d/fgs16_image-placeholder.png") no-repeat scroll 0% 0% / auto padding-box border-box;
    border: 0px none rgb(51, 51, 51);
    font: 14px Montserrat, sans-serif;
    outline: rgb(51, 51, 51) none 0px;
}/*#DIV_61*/

#DIV_61:after {
    border-block-end-color: rgb(51, 51, 51);
    border-block-start-color: rgb(51, 51, 51);
    border-inline-end-color: rgb(51, 51, 51);
    border-inline-start-color: rgb(51, 51, 51);
    caret-color: rgb(51, 51, 51);
    color: rgb(51, 51, 51);
    column-rule-color: rgb(51, 51, 51);
    cursor: pointer;
    overflow-wrap: break-word;
    text-align: center;
    text-decoration: none solid rgb(51, 51, 51);
    text-emphasis-color: rgb(51, 51, 51);
    text-size-adjust: 100%;
    border: 0px none rgb(51, 51, 51);
    font: 14px Montserrat, sans-serif;
    outline: rgb(51, 51, 51) none 0px;
}/*#DIV_61:after*/

#DIV_61:before {
    border-block-end-color: rgb(51, 51, 51);
    border-block-start-color: rgb(51, 51, 51);
    border-inline-end-color: rgb(51, 51, 51);
    border-inline-start-color: rgb(51, 51, 51);
    caret-color: rgb(51, 51, 51);
    color: rgb(51, 51, 51);
    column-rule-color: rgb(51, 51, 51);
    cursor: pointer;
    overflow-wrap: break-word;
    text-align: center;
    text-decoration: none solid rgb(51, 51, 51);
    text-emphasis-color: rgb(51, 51, 51);
    text-size-adjust: 100%;
    border: 0px none rgb(51, 51, 51);
    font: 14px Montserrat, sans-serif;
    outline: rgb(51, 51, 51) none 0px;
}/*#DIV_61:before*/

#DIV_62 {
    align-items: flex-start;
    border-block-end-color: rgb(51, 51, 51);
    border-block-start-color: rgb(51, 51, 51);
    border-inline-end-color: rgb(51, 51, 51);
    border-inline-start-color: rgb(51, 51, 51);
    bottom: 0px;
    box-sizing: border-box;
    caret-color: rgb(51, 51, 51);
    color: rgb(51, 51, 51);
    column-rule-color: rgb(51, 51, 51);
    display: none;
    inset-block-end: 0px;
    inset-block-start: 0px;
    inset-inline-end: 0px;
    inset-inline-start: 0px;
    left: 0px;
    overflow-wrap: break-word;
    pointer-events: none;
    position: absolute;
    right: 0px;
    text-align: center;
    text-decoration: none solid rgb(51, 51, 51);
    text-emphasis-color: rgb(51, 51, 51);
    text-size-adjust: 100%;
    top: 0px;
    z-index: 3;
    border: 0px none rgb(51, 51, 51);
    font: 14px Montserrat, sans-serif;
    outline: rgb(51, 51, 51) none 0px;
}/*#DIV_62*/

#DIV_62:after {
    border-block-end-color: rgb(51, 51, 51);
    border-block-start-color: rgb(51, 51, 51);
    border-inline-end-color: rgb(51, 51, 51);
    border-inline-start-color: rgb(51, 51, 51);
    caret-color: rgb(51, 51, 51);
    color: rgb(51, 51, 51);
    column-rule-color: rgb(51, 51, 51);
    overflow-wrap: break-word;
    pointer-events: none;
    text-align: center;
    text-decoration: none solid rgb(51, 51, 51);
    text-emphasis-color: rgb(51, 51, 51);
    text-size-adjust: 100%;
    border: 0px none rgb(51, 51, 51);
    font: 14px Montserrat, sans-serif;
    outline: rgb(51, 51, 51) none 0px;
}/*#DIV_62:after*/

#DIV_62:before {
    border-block-end-color: rgb(51, 51, 51);
    border-block-start-color: rgb(51, 51, 51);
    border-inline-end-color: rgb(51, 51, 51);
    border-inline-start-color: rgb(51, 51, 51);
    caret-color: rgb(51, 51, 51);
    color: rgb(51, 51, 51);
    column-rule-color: rgb(51, 51, 51);
    overflow-wrap: break-word;
    pointer-events: none;
    text-align: center;
    text-decoration: none solid rgb(51, 51, 51);
    text-emphasis-color: rgb(51, 51, 51);
    text-size-adjust: 100%;
    border: 0px none rgb(51, 51, 51);
    font: 14px Montserrat, sans-serif;
    outline: rgb(51, 51, 51) none 0px;
}/*#DIV_62:before*/

#DIV_63 {
    border-block-end-color: rgb(51, 51, 51);
    border-block-start-color: rgb(51, 51, 51);
    border-inline-end-color: rgb(51, 51, 51);
    border-inline-start-color: rgb(51, 51, 51);
    box-sizing: border-box;
    caret-color: rgb(51, 51, 51);
    color: rgb(51, 51, 51);
    column-rule-color: rgb(51, 51, 51);
    display: flex;
    margin-block-end: 5px;
    margin-block-start: auto;
    margin-inline-end: auto;
    margin-inline-start: auto;
    overflow-wrap: break-word;
    padding-block-end: 3px;
    padding-block-start: 3px;
    padding-inline-end: 3px;
    padding-inline-start: 3px;
    text-align: center;
    text-decoration: none solid rgb(51, 51, 51);
    text-emphasis-color: rgb(51, 51, 51);
    text-size-adjust: 100%;
    background: rgba(0, 0, 0, 0.2) none repeat scroll 0% 0% / auto padding-box border-box;
    border: 0px none rgb(51, 51, 51);
    font: 14px Montserrat, sans-serif;
    margin: auto auto 5px;
    outline: rgb(51, 51, 51) none 0px;
    padding: 3px;
}/*#DIV_63*/

#DIV_63:after {
    border-block-end-color: rgb(51, 51, 51);
    border-block-start-color: rgb(51, 51, 51);
    border-inline-end-color: rgb(51, 51, 51);
    border-inline-start-color: rgb(51, 51, 51);
    caret-color: rgb(51, 51, 51);
    color: rgb(51, 51, 51);
    column-rule-color: rgb(51, 51, 51);
    display: block;
    overflow-wrap: break-word;
    text-align: center;
    text-decoration: none solid rgb(51, 51, 51);
    text-emphasis-color: rgb(51, 51, 51);
    text-size-adjust: 100%;
    border: 0px none rgb(51, 51, 51);
    font: 14px Montserrat, sans-serif;
    outline: rgb(51, 51, 51) none 0px;
}/*#DIV_63:after*/

#DIV_63:before {
    border-block-end-color: rgb(51, 51, 51);
    border-block-start-color: rgb(51, 51, 51);
    border-inline-end-color: rgb(51, 51, 51);
    border-inline-start-color: rgb(51, 51, 51);
    caret-color: rgb(51, 51, 51);
    color: rgb(51, 51, 51);
    column-rule-color: rgb(51, 51, 51);
    display: block;
    overflow-wrap: break-word;
    text-align: center;
    text-decoration: none solid rgb(51, 51, 51);
    text-emphasis-color: rgb(51, 51, 51);
    text-size-adjust: 100%;
    border: 0px none rgb(51, 51, 51);
    font: 14px Montserrat, sans-serif;
    outline: rgb(51, 51, 51) none 0px;
}/*#DIV_63:before*/

#DIV_64, #DIV_65, #DIV_67, #DIV_75, #DIV_77 {
    block-size: 10px;
    border-block-end-color: rgb(51, 51, 51);
    border-block-start-color: rgb(51, 51, 51);
    border-inline-end-color: rgb(51, 51, 51);
    border-inline-start-color: rgb(51, 51, 51);
    box-sizing: border-box;
    caret-color: rgb(51, 51, 51);
    color: rgb(51, 51, 51);
    column-rule-color: rgb(51, 51, 51);
    cursor: pointer;
    height: 10px;
    inline-size: 10px;
    margin-block-end: 2px;
    margin-block-start: 2px;
    margin-inline-end: 2px;
    margin-inline-start: 2px;
    overflow-wrap: break-word;
    text-align: center;
    text-decoration: none solid rgb(51, 51, 51);
    text-emphasis-color: rgb(51, 51, 51);
    text-size-adjust: 100%;
    width: 10px;
    background: rgba(0, 0, 0, 0.4) none repeat scroll 0% 0% / auto padding-box border-box;
    border: 0px none rgb(51, 51, 51);
    flex: 0 0 auto;
    font: 14px Montserrat, sans-serif;
    margin: 2px;
    outline: rgb(51, 51, 51) none 0px;
}/*#DIV_64, #DIV_65, #DIV_67, #DIV_75, #DIV_77*/

#DIV_64:after, #DIV_65:after, #DIV_67:after, #DIV_75:after, #DIV_77:after {
    border-block-end-color: rgb(51, 51, 51);
    border-block-start-color: rgb(51, 51, 51);
    border-inline-end-color: rgb(51, 51, 51);
    border-inline-start-color: rgb(51, 51, 51);
    caret-color: rgb(51, 51, 51);
    color: rgb(51, 51, 51);
    column-rule-color: rgb(51, 51, 51);
    cursor: pointer;
    overflow-wrap: break-word;
    text-align: center;
    text-decoration: none solid rgb(51, 51, 51);
    text-emphasis-color: rgb(51, 51, 51);
    text-size-adjust: 100%;
    border: 0px none rgb(51, 51, 51);
    font: 14px Montserrat, sans-serif;
    outline: rgb(51, 51, 51) none 0px;
}/*#DIV_64:after, #DIV_65:after, #DIV_67:after, #DIV_75:after, #DIV_77:after*/

#DIV_64:before, #DIV_65:before, #DIV_67:before, #DIV_75:before, #DIV_77:before {
    border-block-end-color: rgb(51, 51, 51);
    border-block-start-color: rgb(51, 51, 51);
    border-inline-end-color: rgb(51, 51, 51);
    border-inline-start-color: rgb(51, 51, 51);
    caret-color: rgb(51, 51, 51);
    color: rgb(51, 51, 51);
    column-rule-color: rgb(51, 51, 51);
    cursor: pointer;
    overflow-wrap: break-word;
    text-align: center;
    text-decoration: none solid rgb(51, 51, 51);
    text-emphasis-color: rgb(51, 51, 51);
    text-size-adjust: 100%;
    border: 0px none rgb(51, 51, 51);
    font: 14px Montserrat, sans-serif;
    outline: rgb(51, 51, 51) none 0px;
}/*#DIV_64:before, #DIV_65:before, #DIV_67:before, #DIV_75:before, #DIV_77:before*/

#DIV_66, #DIV_76 {
    block-size: 10px;
    border-block-end-color: rgb(51, 51, 51);
    border-block-start-color: rgb(51, 51, 51);
    border-inline-end-color: rgb(51, 51, 51);
    border-inline-start-color: rgb(51, 51, 51);
    box-sizing: border-box;
    caret-color: rgb(51, 51, 51);
    color: rgb(51, 51, 51);
    column-rule-color: rgb(51, 51, 51);
    cursor: pointer;
    height: 10px;
    inline-size: 10px;
    margin-block-end: 2px;
    margin-block-start: 2px;
    margin-inline-end: 2px;
    margin-inline-start: 2px;
    overflow-wrap: break-word;
    text-align: center;
    text-decoration: none solid rgb(51, 51, 51);
    text-emphasis-color: rgb(51, 51, 51);
    text-size-adjust: 100%;
    width: 10px;
    background: rgba(0, 0, 0, 0.4) none repeat scroll 0% 0% / auto padding-box border-box;
    border: 0px none rgb(51, 51, 51);
    flex: 0 0 auto;
    font: 14px Montserrat, sans-serif;
    margin: 2px;
    outline: rgb(51, 51, 51) none 0px;
}/*#DIV_66, #DIV_76*/

#DIV_66:after, #DIV_76:after {
    border-block-end-color: rgb(51, 51, 51);
    border-block-start-color: rgb(51, 51, 51);
    border-inline-end-color: rgb(51, 51, 51);
    border-inline-start-color: rgb(51, 51, 51);
    caret-color: rgb(51, 51, 51);
    color: rgb(51, 51, 51);
    column-rule-color: rgb(51, 51, 51);
    cursor: pointer;
    overflow-wrap: break-word;
    text-align: center;
    text-decoration: none solid rgb(51, 51, 51);
    text-emphasis-color: rgb(51, 51, 51);
    text-size-adjust: 100%;
    border: 0px none rgb(51, 51, 51);
    font: 14px Montserrat, sans-serif;
    outline: rgb(51, 51, 51) none 0px;
}/*#DIV_66:after, #DIV_76:after*/

#DIV_66:before, #DIV_76:before {
    border-block-end-color: rgb(51, 51, 51);
    border-block-start-color: rgb(51, 51, 51);
    border-inline-end-color: rgb(51, 51, 51);
    border-inline-start-color: rgb(51, 51, 51);
    caret-color: rgb(51, 51, 51);
    color: rgb(51, 51, 51);
    column-rule-color: rgb(51, 51, 51);
    cursor: pointer;
    overflow-wrap: break-word;
    text-align: center;
    text-decoration: none solid rgb(51, 51, 51);
    text-emphasis-color: rgb(51, 51, 51);
    text-size-adjust: 100%;
    border: 0px none rgb(51, 51, 51);
    font: 14px Montserrat, sans-serif;
    outline: rgb(51, 51, 51) none 0px;
}/*#DIV_66:before, #DIV_76:before*/

#DIV_68 {
    border-block-end-color: rgb(51, 51, 51);
    border-block-start-color: rgb(51, 51, 51);
    border-inline-end-color: rgb(51, 51, 51);
    border-inline-start-color: rgb(51, 51, 51);
    bottom: 0px;
    box-sizing: border-box;
    caret-color: rgb(51, 51, 51);
    color: rgb(51, 51, 51);
    column-rule-color: rgb(51, 51, 51);
    display: none;
    inset-block-end: 0px;
    inset-block-start: 0px;
    inset-inline-end: 0px;
    inset-inline-start: 0px;
    left: 0px;
    overflow-wrap: break-word;
    pointer-events: none;
    position: absolute;
    right: 0px;
    text-align: center;
    text-decoration: none solid rgb(51, 51, 51);
    text-emphasis-color: rgb(51, 51, 51);
    text-size-adjust: 100%;
    top: 0px;
    z-index: 4;
    border: 0px none rgb(51, 51, 51);
    font: 14px Montserrat, sans-serif;
    outline: rgb(51, 51, 51) none 0px;
}/*#DIV_68*/

#DIV_68:after {
    border-block-end-color: rgb(51, 51, 51);
    border-block-start-color: rgb(51, 51, 51);
    border-inline-end-color: rgb(51, 51, 51);
    border-inline-start-color: rgb(51, 51, 51);
    caret-color: rgb(51, 51, 51);
    color: rgb(51, 51, 51);
    column-rule-color: rgb(51, 51, 51);
    overflow-wrap: break-word;
    pointer-events: none;
    text-align: center;
    text-decoration: none solid rgb(51, 51, 51);
    text-emphasis-color: rgb(51, 51, 51);
    text-size-adjust: 100%;
    border: 0px none rgb(51, 51, 51);
    font: 14px Montserrat, sans-serif;
    outline: rgb(51, 51, 51) none 0px;
}/*#DIV_68:after*/

#DIV_68:before {
    border-block-end-color: rgb(51, 51, 51);
    border-block-start-color: rgb(51, 51, 51);
    border-inline-end-color: rgb(51, 51, 51);
    border-inline-start-color: rgb(51, 51, 51);
    caret-color: rgb(51, 51, 51);
    color: rgb(51, 51, 51);
    column-rule-color: rgb(51, 51, 51);
    overflow-wrap: break-word;
    pointer-events: none;
    text-align: center;
    text-decoration: none solid rgb(51, 51, 51);
    text-emphasis-color: rgb(51, 51, 51);
    text-size-adjust: 100%;
    border: 0px none rgb(51, 51, 51);
    font: 14px Montserrat, sans-serif;
    outline: rgb(51, 51, 51) none 0px;
}/*#DIV_68:before*/

#DIV_69 {
    border-block-end-color: rgb(51, 51, 51);
    border-block-start-color: rgb(51, 51, 51);
    border-inline-end-color: rgb(51, 51, 51);
    border-inline-start-color: rgb(51, 51, 51);
    box-sizing: border-box;
    caret-color: rgb(51, 51, 51);
    color: rgb(51, 51, 51);
    column-rule-color: rgb(51, 51, 51);
    display: flex;
    margin-block-end: 5px;
    margin-block-start: auto;
    margin-inline-end: auto;
    margin-inline-start: auto;
    max-inline-size: 100%;
    max-width: 100%;
    overflow-wrap: break-word;
    padding-block-end: 10px;
    padding-block-start: 10px;
    padding-inline-end: 30px;
    padding-inline-start: 30px;
    position: relative;
    text-align: center;
    text-decoration: none solid rgb(51, 51, 51);
    text-emphasis-color: rgb(51, 51, 51);
    text-size-adjust: 100%;
    border: 0px none rgb(51, 51, 51);
    font: 14px Montserrat, sans-serif;
    margin: auto auto 5px;
    outline: rgb(51, 51, 51) none 0px;
    padding: 10px 30px;
}/*#DIV_69*/

#DIV_69:after {
    border-block-end-color: rgb(51, 51, 51);
    border-block-start-color: rgb(51, 51, 51);
    border-inline-end-color: rgb(51, 51, 51);
    border-inline-start-color: rgb(51, 51, 51);
    caret-color: rgb(51, 51, 51);
    color: rgb(51, 51, 51);
    column-rule-color: rgb(51, 51, 51);
    display: block;
    overflow-wrap: break-word;
    text-align: center;
    text-decoration: none solid rgb(51, 51, 51);
    text-emphasis-color: rgb(51, 51, 51);
    text-size-adjust: 100%;
    border: 0px none rgb(51, 51, 51);
    font: 14px Montserrat, sans-serif;
    outline: rgb(51, 51, 51) none 0px;
}/*#DIV_69:after*/

#DIV_69:before {
    border-block-end-color: rgb(51, 51, 51);
    border-block-start-color: rgb(51, 51, 51);
    border-inline-end-color: rgb(51, 51, 51);
    border-inline-start-color: rgb(51, 51, 51);
    caret-color: rgb(51, 51, 51);
    color: rgb(51, 51, 51);
    column-rule-color: rgb(51, 51, 51);
    display: block;
    overflow-wrap: break-word;
    text-align: center;
    text-decoration: none solid rgb(51, 51, 51);
    text-emphasis-color: rgb(51, 51, 51);
    text-size-adjust: 100%;
    border: 0px none rgb(51, 51, 51);
    font: 14px Montserrat, sans-serif;
    outline: rgb(51, 51, 51) none 0px;
}/*#DIV_69:before*/

#DIV_70 {
    border-block-end-color: rgb(51, 51, 51);
    border-block-start-color: rgb(51, 51, 51);
    border-inline-end-color: rgb(51, 51, 51);
    border-inline-start-color: rgb(51, 51, 51);
    bottom: 0px;
    box-sizing: border-box;
    caret-color: rgb(51, 51, 51);
    color: rgb(51, 51, 51);
    column-rule-color: rgb(51, 51, 51);
    display: flex;
    inset-block-end: 0px;
    inset-block-start: 0px;
    inset-inline-end: 0px;
    inset-inline-start: 0px;
    left: 0px;
    overflow-wrap: break-word;
    pointer-events: none;
    position: absolute;
    right: 0px;
    text-align: center;
    text-decoration: none solid rgb(51, 51, 51);
    text-emphasis-color: rgb(51, 51, 51);
    text-size-adjust: 100%;
    top: 0px;
    z-index: 2;
    border: 0px none rgb(51, 51, 51);
    font: 14px Montserrat, sans-serif;
    outline: rgb(51, 51, 51) none 0px;
}/*#DIV_70*/

#DIV_70:after {
    border-block-end-color: rgb(51, 51, 51);
    border-block-start-color: rgb(51, 51, 51);
    border-inline-end-color: rgb(51, 51, 51);
    border-inline-start-color: rgb(51, 51, 51);
    caret-color: rgb(51, 51, 51);
    color: rgb(51, 51, 51);
    column-rule-color: rgb(51, 51, 51);
    display: block;
    overflow-wrap: break-word;
    pointer-events: none;
    text-align: center;
    text-decoration: none solid rgb(51, 51, 51);
    text-emphasis-color: rgb(51, 51, 51);
    text-size-adjust: 100%;
    border: 0px none rgb(51, 51, 51);
    font: 14px Montserrat, sans-serif;
    outline: rgb(51, 51, 51) none 0px;
}/*#DIV_70:after*/

#DIV_70:before {
    border-block-end-color: rgb(51, 51, 51);
    border-block-start-color: rgb(51, 51, 51);
    border-inline-end-color: rgb(51, 51, 51);
    border-inline-start-color: rgb(51, 51, 51);
    caret-color: rgb(51, 51, 51);
    color: rgb(51, 51, 51);
    column-rule-color: rgb(51, 51, 51);
    display: block;
    overflow-wrap: break-word;
    pointer-events: none;
    text-align: center;
    text-decoration: none solid rgb(51, 51, 51);
    text-emphasis-color: rgb(51, 51, 51);
    text-size-adjust: 100%;
    border: 0px none rgb(51, 51, 51);
    font: 14px Montserrat, sans-serif;
    outline: rgb(51, 51, 51) none 0px;
}/*#DIV_70:before*/

#DIV_71 {
    block-size: 32px;
    border-block-end-color: rgb(51, 51, 51);
    border-block-start-color: rgb(51, 51, 51);
    border-inline-end-color: rgb(51, 51, 51);
    border-inline-start-color: rgb(51, 51, 51);
    box-sizing: border-box;
    caret-color: rgb(51, 51, 51);
    color: rgb(51, 51, 51);
    column-rule-color: rgb(51, 51, 51);
    cursor: pointer;
    height: 32px;
    inline-size: 20px;
    margin-block-end: auto;
    margin-block-start: auto;
    margin-inline-end: auto;
    overflow-wrap: break-word;
    text-align: center;
    text-decoration: none solid rgb(51, 51, 51);
    text-emphasis-color: rgb(51, 51, 51);
    text-size-adjust: 100%;
    width: 20px;
    background: rgba(0, 0, 0, 0) url("https://massage51.ru/d/fgs16_image-placeholder.png") no-repeat scroll 0% 0% / auto padding-box border-box;
    border: 0px none rgb(51, 51, 51);
    flex: 0 0 auto;
    font: 14px Montserrat, sans-serif;
    margin: auto auto auto 0px;
    outline: rgb(51, 51, 51) none 0px;
}/*#DIV_71*/

#DIV_71:after {
    border-block-end-color: rgb(51, 51, 51);
    border-block-start-color: rgb(51, 51, 51);
    border-inline-end-color: rgb(51, 51, 51);
    border-inline-start-color: rgb(51, 51, 51);
    caret-color: rgb(51, 51, 51);
    color: rgb(51, 51, 51);
    column-rule-color: rgb(51, 51, 51);
    cursor: pointer;
    overflow-wrap: break-word;
    text-align: center;
    text-decoration: none solid rgb(51, 51, 51);
    text-emphasis-color: rgb(51, 51, 51);
    text-size-adjust: 100%;
    border: 0px none rgb(51, 51, 51);
    font: 14px Montserrat, sans-serif;
    outline: rgb(51, 51, 51) none 0px;
}/*#DIV_71:after*/

#DIV_71:before {
    border-block-end-color: rgb(51, 51, 51);
    border-block-start-color: rgb(51, 51, 51);
    border-inline-end-color: rgb(51, 51, 51);
    border-inline-start-color: rgb(51, 51, 51);
    caret-color: rgb(51, 51, 51);
    color: rgb(51, 51, 51);
    column-rule-color: rgb(51, 51, 51);
    cursor: pointer;
    overflow-wrap: break-word;
    text-align: center;
    text-decoration: none solid rgb(51, 51, 51);
    text-emphasis-color: rgb(51, 51, 51);
    text-size-adjust: 100%;
    border: 0px none rgb(51, 51, 51);
    font: 14px Montserrat, sans-serif;
    outline: rgb(51, 51, 51) none 0px;
}/*#DIV_71:before*/

#DIV_72 {
    block-size: 32px;
    border-block-end-color: rgb(51, 51, 51);
    border-block-start-color: rgb(51, 51, 51);
    border-inline-end-color: rgb(51, 51, 51);
    border-inline-start-color: rgb(51, 51, 51);
    box-sizing: border-box;
    caret-color: rgb(51, 51, 51);
    color: rgb(51, 51, 51);
    column-rule-color: rgb(51, 51, 51);
    cursor: pointer;
    height: 32px;
    inline-size: 20px;
    margin-block-end: auto;
    margin-block-start: auto;
    margin-inline-start: auto;
    overflow-wrap: break-word;
    text-align: center;
    text-decoration: none solid rgb(51, 51, 51);
    text-emphasis-color: rgb(51, 51, 51);
    text-size-adjust: 100%;
    width: 20px;
    background: rgba(0, 0, 0, 0) url("https://massage51.ru/d/fgs16_image-placeholder.png") no-repeat scroll 0% 0% / auto padding-box border-box;
    border: 0px none rgb(51, 51, 51);
    font: 14px Montserrat, sans-serif;
    margin: auto 0px auto auto;
    outline: rgb(51, 51, 51) none 0px;
}/*#DIV_72*/

#DIV_72:after {
    border-block-end-color: rgb(51, 51, 51);
    border-block-start-color: rgb(51, 51, 51);
    border-inline-end-color: rgb(51, 51, 51);
    border-inline-start-color: rgb(51, 51, 51);
    caret-color: rgb(51, 51, 51);
    color: rgb(51, 51, 51);
    column-rule-color: rgb(51, 51, 51);
    cursor: pointer;
    overflow-wrap: break-word;
    text-align: center;
    text-decoration: none solid rgb(51, 51, 51);
    text-emphasis-color: rgb(51, 51, 51);
    text-size-adjust: 100%;
    border: 0px none rgb(51, 51, 51);
    font: 14px Montserrat, sans-serif;
    outline: rgb(51, 51, 51) none 0px;
}/*#DIV_72:after*/

#DIV_72:before {
    border-block-end-color: rgb(51, 51, 51);
    border-block-start-color: rgb(51, 51, 51);
    border-inline-end-color: rgb(51, 51, 51);
    border-inline-start-color: rgb(51, 51, 51);
    caret-color: rgb(51, 51, 51);
    color: rgb(51, 51, 51);
    column-rule-color: rgb(51, 51, 51);
    cursor: pointer;
    overflow-wrap: break-word;
    text-align: center;
    text-decoration: none solid rgb(51, 51, 51);
    text-emphasis-color: rgb(51, 51, 51);
    text-size-adjust: 100%;
    border: 0px none rgb(51, 51, 51);
    font: 14px Montserrat, sans-serif;
    outline: rgb(51, 51, 51) none 0px;
}/*#DIV_72:before*/

#DIV_73 {
    border-block-end-color: rgb(51, 51, 51);
    border-block-start-color: rgb(51, 51, 51);
    border-inline-end-color: rgb(51, 51, 51);
    border-inline-start-color: rgb(51, 51, 51);
    box-sizing: border-box;
    caret-color: rgb(51, 51, 51);
    color: rgb(51, 51, 51);
    column-rule-color: rgb(51, 51, 51);
    overflow-wrap: break-word;
    text-align: center;
    text-decoration: none solid rgb(51, 51, 51);
    text-emphasis-color: rgb(51, 51, 51);
    text-size-adjust: 100%;
    border: 0px none rgb(51, 51, 51);
    font: 14px Montserrat, sans-serif;
    outline: rgb(51, 51, 51) none 0px;
}/*#DIV_73*/

#DIV_73:after {
    border-block-end-color: rgb(51, 51, 51);
    border-block-start-color: rgb(51, 51, 51);
    border-inline-end-color: rgb(51, 51, 51);
    border-inline-start-color: rgb(51, 51, 51);
    caret-color: rgb(51, 51, 51);
    color: rgb(51, 51, 51);
    column-rule-color: rgb(51, 51, 51);
    overflow-wrap: break-word;
    text-align: center;
    text-decoration: none solid rgb(51, 51, 51);
    text-emphasis-color: rgb(51, 51, 51);
    text-size-adjust: 100%;
    border: 0px none rgb(51, 51, 51);
    font: 14px Montserrat, sans-serif;
    outline: rgb(51, 51, 51) none 0px;
}/*#DIV_73:after*/

#DIV_73:before {
    border-block-end-color: rgb(51, 51, 51);
    border-block-start-color: rgb(51, 51, 51);
    border-inline-end-color: rgb(51, 51, 51);
    border-inline-start-color: rgb(51, 51, 51);
    caret-color: rgb(51, 51, 51);
    color: rgb(51, 51, 51);
    column-rule-color: rgb(51, 51, 51);
    overflow-wrap: break-word;
    text-align: center;
    text-decoration: none solid rgb(51, 51, 51);
    text-emphasis-color: rgb(51, 51, 51);
    text-size-adjust: 100%;
    border: 0px none rgb(51, 51, 51);
    font: 14px Montserrat, sans-serif;
    outline: rgb(51, 51, 51) none 0px;
}/*#DIV_73:before*/

#DIV_74 {
    border-block-end-color: rgb(51, 51, 51);
    border-block-start-color: rgb(51, 51, 51);
    border-inline-end-color: rgb(51, 51, 51);
    border-inline-start-color: rgb(51, 51, 51);
    box-sizing: border-box;
    caret-color: rgb(51, 51, 51);
    color: rgb(51, 51, 51);
    column-rule-color: rgb(51, 51, 51);
    display: flex;
    margin-block-end: auto;
    margin-block-start: auto;
    margin-inline-end: auto;
    margin-inline-start: auto;
    overflow-wrap: break-word;
    padding-block-end: 3px;
    padding-block-start: 3px;
    padding-inline-end: 3px;
    padding-inline-start: 3px;
    text-align: center;
    text-decoration: none solid rgb(51, 51, 51);
    text-emphasis-color: rgb(51, 51, 51);
    text-size-adjust: 100%;
    background: rgba(0, 0, 0, 0.2) none repeat scroll 0% 0% / auto padding-box border-box;
    border: 0px none rgb(51, 51, 51);
    font: 14px Montserrat, sans-serif;
    margin: auto;
    outline: rgb(51, 51, 51) none 0px;
    padding: 3px;
}/*#DIV_74*/

#DIV_74:after {
    border-block-end-color: rgb(51, 51, 51);
    border-block-start-color: rgb(51, 51, 51);
    border-inline-end-color: rgb(51, 51, 51);
    border-inline-start-color: rgb(51, 51, 51);
    caret-color: rgb(51, 51, 51);
    color: rgb(51, 51, 51);
    column-rule-color: rgb(51, 51, 51);
    display: block;
    overflow-wrap: break-word;
    text-align: center;
    text-decoration: none solid rgb(51, 51, 51);
    text-emphasis-color: rgb(51, 51, 51);
    text-size-adjust: 100%;
    border: 0px none rgb(51, 51, 51);
    font: 14px Montserrat, sans-serif;
    outline: rgb(51, 51, 51) none 0px;
}/*#DIV_74:after*/

#DIV_74:before {
    border-block-end-color: rgb(51, 51, 51);
    border-block-start-color: rgb(51, 51, 51);
    border-inline-end-color: rgb(51, 51, 51);
    border-inline-start-color: rgb(51, 51, 51);
    caret-color: rgb(51, 51, 51);
    color: rgb(51, 51, 51);
    column-rule-color: rgb(51, 51, 51);
    display: block;
    overflow-wrap: break-word;
    text-align: center;
    text-decoration: none solid rgb(51, 51, 51);
    text-emphasis-color: rgb(51, 51, 51);
    text-size-adjust: 100%;
    border: 0px none rgb(51, 51, 51);
    font: 14px Montserrat, sans-serif;
    outline: rgb(51, 51, 51) none 0px;
}/*#DIV_74:before*/

#DIV_78 {
    block-size: 10px;
    border-block-end-color: rgb(51, 51, 51);
    border-block-start-color: rgb(51, 51, 51);
    border-inline-end-color: rgb(51, 51, 51);
    border-inline-start-color: rgb(51, 51, 51);
    box-sizing: border-box;
    caret-color: rgb(51, 51, 51);
    color: rgb(51, 51, 51);
    column-rule-color: rgb(51, 51, 51);
    cursor: pointer;
    height: 10px;
    inline-size: 10px;
    margin-block-end: 2px;
    margin-block-start: 2px;
    margin-inline-end: 2px;
    margin-inline-start: 2px;
    overflow-wrap: break-word;
    text-align: center;
    text-decoration: none solid rgb(51, 51, 51);
    text-emphasis-color: rgb(51, 51, 51);
    text-size-adjust: 100%;
    width: 10px;
    background: rgba(0, 0, 0, 0.4) none repeat scroll 0% 0% / auto padding-box border-box;
    border: 0px none rgb(51, 51, 51);
    flex: 0 0 auto;
    font: 14px Montserrat, sans-serif;
    margin: 2px;
    outline: rgb(51, 51, 51) none 0px;
}/*#DIV_78*/

#DIV_78:after {
    border-block-end-color: rgb(51, 51, 51);
    border-block-start-color: rgb(51, 51, 51);
    border-inline-end-color: rgb(51, 51, 51);
    border-inline-start-color: rgb(51, 51, 51);
    caret-color: rgb(51, 51, 51);
    color: rgb(51, 51, 51);
    column-rule-color: rgb(51, 51, 51);
    cursor: pointer;
    overflow-wrap: break-word;
    text-align: center;
    text-decoration: none solid rgb(51, 51, 51);
    text-emphasis-color: rgb(51, 51, 51);
    text-size-adjust: 100%;
    border: 0px none rgb(51, 51, 51);
    font: 14px Montserrat, sans-serif;
    outline: rgb(51, 51, 51) none 0px;
}/*#DIV_78:after*/

#DIV_78:before {
    border-block-end-color: rgb(51, 51, 51);
    border-block-start-color: rgb(51, 51, 51);
    border-inline-end-color: rgb(51, 51, 51);
    border-inline-start-color: rgb(51, 51, 51);
    caret-color: rgb(51, 51, 51);
    color: rgb(51, 51, 51);
    column-rule-color: rgb(51, 51, 51);
    cursor: pointer;
    overflow-wrap: break-word;
    text-align: center;
    text-decoration: none solid rgb(51, 51, 51);
    text-emphasis-color: rgb(51, 51, 51);
    text-size-adjust: 100%;
    border: 0px none rgb(51, 51, 51);
    font: 14px Montserrat, sans-serif;
    outline: rgb(51, 51, 51) none 0px;
}/*#DIV_78:before*/

.menu__icons{
    margin: 10px 13px;
}