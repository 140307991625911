.hero__home {
    z-index: -999;
    height: 20px;
}

.relax_h1{
    text-align: center;
    justify-content: center;
    text-align: center;
    display: inline-block;
    max-height: auto;
    margin-top: -145px;
}


.relax__logo {
    width: 165px;
    height: 165px;
    min-width: 100%;
    min-height: 100%;
    margin-top: 5px;
}

.video__background {
    position: absolute;
    top: 0px;
    left: 0px;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    overflow: hidden;
}

.image__background {
    position: absolute;
    top: 0px;
    left: 0px;
    min-width: 100%;
    min-height: 100%;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    opacity:70%;
}

.text1{
    height: 47px;
    margin:0;
    margin-top: -50px;
}