/*========== Google Fonts ==========*/
@import url("https://fonts.googleapis.com/css2?family=Encode+Sans+Expanded:wght@200;400;600&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

/* iframe#webpack-dev-server-client-overlay{display:none!important} */

/*======= CSS Reset =======*/
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', serif;
}


html {
  scroll-behavior: smooth;
  width: 100%;
    max-width: 100%;
    overflow-x: hidden;
}

/*======= Variables =======*/
:root {
  --body-color: #f3f3fc;
  --container-color: #010606;
  --first-color: #EC134D	;
  --first-color-alt: #009955;
  --scroll-bar-color: #c6c8dd;
  --scroll-thumb-color: #EC134D;
}

/*========== SCROLL BAR ==========*/
::-webkit-scrollbar {
  width: 0.61rem;
  background-color: var(--scroll-bar-color);
  border-radius: 0.5rem;
}

::-webkit-scrollbar-thumb {
  background-color: var(--first-color);
  border-radius: 0.5rem;
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--scroll-thumb-color);
}
